import React from "react";
import { Router } from "@reach/router";
import ManagerLogin from "./components/pages/ManagerLogin";
import DashboardScreens from "./components/pages/DashboardScreens";
// import MyTasks from "./components/pages/MyTasks";
import PendingActions from "./components/internal/PendingActions";
import Uploads from "./components/internal/Uploads";
import VerifyDetails from "./components/internal/VerifyDetails";
import LoginPath from "./components/pages/ManagerLogin/LoginPath";
import PartyDetails from "./components/internal/PartyDetails";
import MyCases from "./components/internal/MyCases";
import MyProfiles from "./components/internal/MyProfiles";
import CaseDetails from "./components/pages/CaseDetails";
// import Configurations from "./components/pages/Configurations";
// import MyConfigurations from "./components/internal/MyConfigurations";
// import UpdateTerminologies from "./components/internal/UpdateTerminologies";
// import UpdateFAQs from "./components/pages/UpdateFAQs";
import ProfileDetails from "./components/pages/ProfileDetails";
import BulkUploads from "./components/pages/BulkUploads";
import Parties from "./components/internal/MyParties";
import MailRedirection from "./components/pages/MailRedirection";
import ProformaInvoiceListing from "./components/internal/ProformaInvoiceListing";
import PaymentHistoryPage from "./components/internal/PaymentHistoryPage";
import RecordingsPage from "./components/internal/RecordingsPage/index";
import GuestGenericMeeting from "./components/pages/JoinGenericMeeting";
import TimeSlideTranscription from "./components/pages/TimeSlideTranscription/index";
import RecordingsTimeSlide from "./components/pages/RecordingsTimeSlide";
import LiveTranscription from "./components/internal/TimeSlideTranscript/LiveTranscription";
import TranscriberTable from "./components/internal/TranscriberTable";
import ViewTranscriber from "./components/internal/ViewTranscriber/index";
import CreateTranscriber from "./components/internal/CreateTranscriber/index";
import EditTranscriber from "./components/internal/EditTranscriber/EditTranscriber";
import ChangePassword from "./components/pages/ChangePassword/index";
import AudioTranscription from "./components/pages/TimeSlideTranscription/AudioTranscription";
import MeetingDetails from "./components/pages/MeetingDetails/index";
import SessionManagement from "./components/pages/SessionManagement";
import CrossExamination from "./components/internal/CrossExamination/index";
import CrossExaminationTranscript from "./components/internal/CrossExamination/CrossExaminationTranscripts";
// import NeutralView from "./components/pages/NeutralView/index";
// import NeutralViewTable from "./components/internal/NeutralViewTable/index";
// import CreateNeutral from "./components/internal/CreateNeutral/index";
// import EditNeutral from "./components/internal/EditNeutral/index";
// import ViewNeutral from "./components/internal/ViewNeutral";
import ViewOnlyUser from "./components/internal/ViewOnlyUser/index";
import CreateViewOnlyUser from "./components/internal/ViewOnlyUser/CreateViewOnlyUser";
import UpdateViewOnlyUser from "./components/internal/ViewOnlyUser/UpdateViewOnlyUser";
import BulkNotice from "./components/internal/NoticeGeneration/index";
// import DailyCause from "./components/internal/DailyCause/index";
import DailyCause from "./components/calender/DailyCause";
import MisDashboard from "./components/Mis";
import ViewCases from "./components/ViewCases";
import BulkUploadRename from "./components/internal/BulkUploadRename";

/* Bulk upload reports */
import BulkUploadReports from "./components/internal/BulkUploadReports/index.js";
import MainRoute from "./components/pages/MainRoute/index.js";

const AppRouter = () => {
  return (
    <Router>
      <LoginPath path="/">
        <ManagerLogin path="/" />
      </LoginPath>
      <DashboardScreens path="/dashboard">
        <MainRoute path="/case-manager">
          <ChangePassword path="/change-password" />
          <PendingActions path="/" />
          <ProformaInvoiceListing path="/proforma-invoice" />
          <Uploads path="/uploads" />
          <VerifyDetails path="/verify-parties" />
          <PartyDetails path="/verify-parties/:partyId" />
          <BulkNotice path="/bulk-notice" />
          <BulkUploadReports path="/document-reports" />

          {/* Negotiation Route */}

          <MyCases path="/negotiation" type="negotiation" />
          <CaseDetails path="/negotiation/:id" />

          {/* Mediation Route  */}

          <MyCases path="/mediation" type="mediation" />
          <CaseDetails path="/mediation/:id" />
          <TimeSlideTranscription
            path="/mediation/:id/transcription"
            type="mediation"
          />
          <AudioTranscription
            path="/mediation/:id/split-transcription"
            type="mediation"
          />
          <LiveTranscription
            path="/mediation/:id/livetranscription"
            type="mediation"
          />
          <CrossExamination
            path="/mediation/:id/cross-examination"
            type="mediation"
          />
          <CrossExaminationTranscript
            path="/mediation/:id/cross-examination-transcript"
            type="mediation"
          />

          {/* Arbitration Route */}

          <MyCases path="/arbitration" type="arbitration" />
          <CaseDetails path="/arbitration/:id" />
          <ViewCases path="/arbitration/view/:id" />
          <TimeSlideTranscription
            path="/arbitration/:id/transcription"
            type="arbitration"
          />
          <AudioTranscription
            path="/arbitration/:id/split-transcription"
            type="arbitration"
          />
          <LiveTranscription
            path="/arbitration/:id/livetranscription"
            type="arbitration"
          />
          <CrossExamination
            path="/arbitration/:id/cross-examination"
            type="arbitration"
          />
          <CrossExaminationTranscript
            path="/arbitration/:id/cross-examination-transcript"
            type="arbitration"
          />

          <Parties path="/parties" />
          <PartyDetails path="/parties/:partyId" isFromListing={true} />

          <MyProfiles path="/profiles" />
          <ProfileDetails path="/profiles/:userId" />
          <CreateViewOnlyUser path="/profiles/create-view-only-user" />
          <ViewOnlyUser path="/profiles/view-only-user/:id" />
          <UpdateViewOnlyUser path="/profiles/edit/:id" />

          <BulkUploads path="/bulk-uploads" />

          <PaymentHistoryPage path="/payments" />
          <RecordingsPage path="/generic-meeting" />
          <RecordingsTimeSlide path="/generic-meeting/transcription" />
          <MeetingDetails path="/meeting-details" />
          <SessionManagement path="/meeting-details/session-management" />
          <DailyCause path="/daily-cause" />
          <TranscriberTable path="/transcriber" />
          <CreateTranscriber path="/transcriber/create-transcriber" />
          <EditTranscriber path="/transcriber/edit/:id" />
          <ViewTranscriber path="/transcriber/:id" />
          <MisDashboard path="/mis-dashboard" />
          <BulkUploadRename path="/tools" />
        </MainRoute>
        <MailRedirection path="/cases/:id" redirectTo="case" />
      </DashboardScreens>
      <GuestGenericMeeting path="/joinMeeting/:code" />
    </Router>
  );
};

export default AppRouter;
