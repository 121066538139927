import API from "../api/manager";
import config from "../api/config";

const index = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.index + query,
  });
};

const stats = () => {
  return API({
    method: "GET",
    url: config.urls.case.stats,
  });
};

const caseStats = (param) => {
  return API({
    method: "GET",
    url: config.urls.case.caseStats + param,
  });
};

const uploads = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.uploads + query,
  });
};

const bulkUpload = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.bulkUpload + query,
  });
};

const saveSummary = (data, id, action) => {
  return API({
    method: "POST",
    url: config.urls.case.saveSummary + `/${id}/${action}`,
    data,
  });
};

const uploadAgreement = (data, caseId) => {
  return API({
    method: "POST",
    url: `${config.urls.case.caseAgreementUpload}/${caseId}/${config.urls.case.uploadAgreement}`,
    data,
  });
};
const updateAgreement = (data, caseId, docId) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.caseAgreementUpload}/${caseId}/${config.urls.case.uploadAgreement}/${docId}`,
    data,
  });
};

const offlinePayment = (data, caseId) => {
  return API({
    method: "POST",
    url: `${config.urls.case.offlinePayment}/${caseId}`,
    data,
  });
};

const caseDocumentListing = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.case_document_uploads + query,
  });
};

const uploadStats = () => {
  return API({
    method: "GET",
    url: config.urls.case.uploadStats,
  });
};

const caseAddDocument = (data, id) => {
  return API({
    method: "POST",
    url: config.urls.case.uploadCase + `/${id}/dealAgreement`,
    data,
  });
};

const negotiations = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.case + `/${id}/negotiations`,
  });
};

const getCase = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.index + `/${id}`,
  });
};

const downloadCaseAgreement = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.uploadCase + `/${id}/negotiationAgreement`,
  });
};

const getcaseDocuments = (id, query) => {
  return API({
    method: "GET",
    url:
      config.urls.case.getCaseDocuments +
      `/case/${id}/dealAgreement` +
      `${query}`,
  });
};

const deleteDocument = (id, docId) => {
  return API({
    method: "DELETE",
    url: config.urls.case.getCaseDocuments + `/case/${id}/${docId}`,
  });
};

const getPendingActions = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.pendingAction + query,
  });
};

const sendRemainder = (kind, id) => {
  return API({
    method: "POST",
    url: config.urls.case.pendingAction + `/${id}/${kind}`,
  });
};

const assignMediator = (data, managerId) => {
  return API({
    method: "PUT",
    url: config.urls.case.assignCase + `/${data?.id}/${managerId}`,
    data: {},
  });
};

const refundPayment = (data) => {
  return API({
    method: "POST",
    url: config.urls.case.paymentRefund,
    data,
  });
};

const updateCase = (caseId, data) => {
  return API({
    method: "PUT",
    url: config.urls.case.index + `/${caseId}`,
    data,
  });
};

const mediations = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.comments}/${id}${query}`,
  });
};

const sendComment = (id, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.comments}/${id}`,
    data,
  });
};

const updateComment = (id, data, commentId) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.comments}/${id}/${commentId}`,
    data,
  });
};

const deleteComment = (id, commentId) => {
  return API({
    method: "DELETE",
    url: `${config.urls.case.comments}/${id}/${commentId}`,
    data: {},
  });
};

const mediationSuccess = (id, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.mediation}/${id}/success`,
    data: {},
  });
};

const quitMediation = (id, data, action = "closedByCaseManager") => {
  return API({
    method: "DELETE",
    url: `${config.urls.case.mediation}/${id}/${action}`,
    data,
  });
};

const quitNegotiation = (id, data, action = "closedByCaseManager") => {
  return API({
    method: "DELETE",
    url: `${config.urls.case.negotiation}/${id}/${action}`,
    data,
  });
};

const removeMediator = (id) => {
  return API({
    method: "DELETE",
    url: `${config.urls.case.mediation}/${id}/remove-mediator`,
    data: {},
  });
};

const getPayments = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.index + `/${id}/payments`,
  });
};
const getOrders = (query) => {
  return API({ method: "GET", url: config.urls.case.orders + query });
};

const getNotificationCount = () => {
  return API({ method: "GET", url: config.urls.case.notificationCount });
};

const clearNotification = () => {
  return API({ method: "POST", url: config.urls.notificationCount, data: {} });
};

const deleteDraftCase = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.draft + `/${id}`,
    data: {},
  });
};

const deleteSelectedCases = () => {
  return API({
    method: "DELETE",
    url: config.urls.case.selectDraft,
    data: {},
  });
};

const addAllCases = (query) => {
  return API({
    method: "POST",
    url: config.urls.case.select + query,
    data: {},
  });
};

const removeAllCases = (query) => {
  return API({
    method: "DELETE",
    url: config.urls.case.select + query,
    data: {},
  });
};

const selectCaseItem = (id) => {
  return API({
    method: "POST",
    url: config.urls.case.select + `/${id}`,
    data: {},
  });
};

const getMeeting = (id) => {
  return API({
    method: "GET",
    url: config.urls.meeting.meetings + `/${id}`,
  });
};

const removeCaseItem = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.select + `/${id}`,
    data: {},
  });
};

const getPartyListByCaseId = (caseId, param) => {
  return API({
    method: "GET",
    url: config.urls.case.caseIndex + `/${caseId}/parties${param}`,
  });
};

const addNotes = (data, caseId) => {
  return API({
    method: "POST",
    url: `${config.urls.case.notes}/${caseId}`,
    data,
  });
};

const getNotes = (caseId, query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.notes}/${caseId}${query}`,
  });
};

const updateCaseEmail = (caseId, data) => {
  return API({
    method: "PUT",
    url: config.urls.party.resupdate + `/${caseId}`,
    data,
  });
};

const getGuestUserMeeting = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.guestmeetingUrl}/${caseId}`,
  });
};

const getMeetingVideo = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.meetingRecordings}/${id}${query}`,
  });
};

const changeDocType = (data, caseId, docId) => {
  return API({
    method: "PUT",
    url: config.urls.case.uploadDocCase + `/${caseId}/${docId}`,
    data,
  });
};

const addTranscript = (data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.transcripts}`,
    data,
  });
};

const updateTranscript = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.case.transcripts + `/${id}`,
    data,
  });
};

const deleteTranscriptDoc = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.transcripts + `/${id}`,
    data: {},
  });
};

const nextHearingDate = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.case.index + `/${id}`,
    data,
  });
};

const updateClaimDescription = (id, data) => {
  return API({
    method: "PUT",
    url: config.urls.case.updateClaimDescription + `/${id}`,
    data,
  });
};

const updateCaseReopen = (id, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.reOpenCase}/${id}`,
    data,
  });
};

const getRefundHistory = (query) => {
  return API({ method: "GET", url: config.urls.payment.payments + query });
};

const joinMeeting = (caseManagerId) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.genericMeetingJoin}/${caseManagerId}`,
  });
};

const getRecordings = (query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.genericMeetingRecordings}${query}`,
  });
};

const getMeetingLink = () => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.genericMeetingUrl}`,
  });
};

const getGenericMeeting = (data, code) => {
  return API(
    {
      method: "POST",
      url: `${config.urls.meeting.genericMeeting}/${code}`,
      data,
    },
    false,
  );
};

const getTranscriptionData = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getTranscriptionData}/${id}`,
  });
};

const transcriptionControlCM = (status) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.transcriptionControlCM}/${status}`,
  });
};

const GetMeetingcases = () => {
  return API({
    method: "GET",
    url: config.urls.meeting.mettingcase,
  });
};

const getLiveTranscription = (transcriptId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getLiveTranscription}/${transcriptId}`,
  });
};

const enableLiveTranscription = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.enableLiveTranscription}/${id}`,
  });
};

const getLiveTranscriptionData = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getLiveTranscriptionData}/${id}`,
  });
};

const enableLiveTranscriptionCommon = () => {
  return API({
    method: "GET",
    url: `${config.urls.case.enableLiveTranscription}`,
  });
};

const getLiveTranscriptionDataGenric = () => {
  return API({
    method: "GET",
    url: `${config.urls.case.getLiveTranscriptionData}`,
  });
};

const updateLiveTranscriptionData = (data, id) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.updateLiveTranscriptionData}/${id}`,
    data,
  });
};

const updateTranscriptionData = (data, id) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.updateTranscriptionData}/${id}`,
    data,
  });
};

const genricStopTranscription = () => {
  return API({
    method: "PUT",
    url: `${config.urls.meeting.stopTranscription}`,
  });
};

const stopTranscription = (caseId) => {
  return API({
    method: "PUT",
    url: `${config.urls.meeting.stopTranscription}/${caseId}`,
  });
};

const transcriptionSettings = (data) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.transcriptionControlCM}`,
    data,
  });
};

const publishTranscripts = (transcriptId, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.meeting.publishTranscripts}/${transcriptId}`,
    data,
  });
};

const presidingArbitrator = (caseId, arbitratorId, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.presidingArbitrator}/${caseId}/${arbitratorId}`,
    data,
  });
};

const startTranscription = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.startTranscription}/${caseId}`,
  });
};

const GetMeetingAccesscases = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.mettingcase}/${caseId}`,
  });
};

const transcriptionControl = (id, status) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.transcriptionControl}/${id}/${status}`,
  });
};

const caseTranscriptionSettings = (id, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.transcriptionControl}/${id}`,
    data,
  });
};

const RecordingControl = (id, recordingStatus) => {
  return API({
    method: "PUT",
    url: config.urls.meeting.recordControl + `/${id}/${recordingStatus}`,
  });
};

const DisableEnableMeeting = (id, meetingStatus) => {
  return API({
    method: "PUT",
    url: config.urls.meeting.meetingControl + `/${id}/${meetingStatus}`,
  });
};

const genricstartTranscription = () => {
  return API({
    method: "GET",
    url: `${config.urls.case.startTranscription}`,
  });
};

const getAudioDetails = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.getMeetingAudioDetails}/${id}${query}`,
  });
};

const getSplitAudio = (query, id) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.getAudioFile}/${id}${query}`,
  });
};

const getLiveTranscriptDet = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.liveTranscriptDet}/${id}${query}`,
  });
};

const getTranscriptionStatus = (meetingId) => {
  return API({
    method: "GET",
    url: `${config.urls.transcription.getTranscriptionStatus}/${meetingId}`,
  });
};

const moveTranscriptToDoc = (meetingId) => {
  return API({
    method: "DELETE",
    url: `${config.urls.transcription.moveTranscriptToDoc}/${meetingId}`,
  });
};

const downloadComments = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.downloadComments}/${id}`,
  });
};

const getBundleName = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.bundle.customBundle}/${id}`,
  });
};

const downloadTranscripts = (data) => {
  return API({
    method: "POST",
    url: `/downloadTranscripts`,
    data,
  });
};

const getTranscribersList = (id) => {
  return API({
    method: "GET",
    url: config.urls.transcribers.getTranscribersList + `/${id}`,
  });
};

const assignTranscribers = (data) => {
  return API({
    method: "PUT",
    url: config.urls.transcribers.assignTranscribers,
    data,
  });
};

const getMeetingAudioTranscript = (meetingAudioId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getMeetingAudioTranscript}/${meetingAudioId}`,
  });
};

const updateAudioTranscriptData = (data, meetingAudioId) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.updateAudioTranscriptData}/${meetingAudioId}`,
    data,
  });
};

const mergeAudioTranscriptData = (data) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.mergeAudioTranscriptData}`,
    data,
  });
};

const NeutralBulkCase = (data) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.index}`,
    data,
  });
};

const getCasesDetails = (query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.getCasesDetails}/${query}`,
  });
};

const getCaseMeetingDetails = (meetingId, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.getCaseMeetingDetails}/${meetingId}${query}`,
  });
};

const updateMeetingDetails = (data) => {
  return API({
    method: "POST",
    url: config.urls.meeting.updateMeetingDetails,
    data,
  });
};

const getExcelFields = (data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.getExcelFields}`,
    data,
  });
};

const bulkNoticeGenerate = (data) => {
  return API({
    method: "POST",
    url: config.urls.case.bulkNoticeGenerate,
    data,
  });
};

const postTranscriptionData = (data) => {
  return API({
    method: "POST",
    url: config.urls.transcription.postTranscriptionData,
    data,
  });
};

const getTranscriptionDataCrossAndRunning = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getTranscriptionData}/${caseId}`,
  });
};

const updateXExamTranscript = (data, meetingId) => {
  return API({
    method: "PUT",
    url: `${config.urls.transcription.updateXExamTranscript}/${meetingId}`,
    data,
  });
};

const moveTranscriptToRecordings = (meetingId) => {
  return API({
    method: "DELETE",
    url: `${config.urls.transcription.moveTranscriptToRecordings}/${meetingId}`,
    data: {},
  });
};

const updateXExamTranscriptDoc = (data, transcriptId) => {
  return API({
    method: "PUT",
    url: `${config.urls.transcription.updateXExamTranscriptDoc}/${transcriptId}`,
    data,
  });
};

const selectMeetingAudioDetails = (data) => {
  return API({
    method: "PUT",
    url: `${config.urls.meeting.selectMeetingAudioDetails}`,
    data,
  });
};

const getPassiveNeutral = (query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.passiveusers}${query}`,
  });
};

const getIndividualUser = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.passiveusers}/${id}`,
  });
};

const disableEnableNeutral = (id, status) => {
  return API({
    method: "DELETE",
    url: config.urls.case.passiveusers + `/${id}/${status}`,
  });
};

const createPassiveNeutral = (data) => {
  return API({
    method: "POST",
    url: config.urls.case.passiveNeutral,
    data,
  });
};

const updatePassiveNeutral = (data, id) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.passiveNeutral}/${id}`,
    data,
  });
};

const removePassiveUser = (caseId, mediatorId) => {
  return API({
    method: "DELETE",
    url: config.urls.case.removePassiveUser + `/${caseId}/${mediatorId}`,
  });
};

const updateCaseStatus = (caseId, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.index}/${caseId}`,
    data,
  });
};

const getDocumentList = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.bundle.bundleDocList}/${caseId}`,
  });
};

const sendDocument = (caseId, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.sendDocument}/${caseId}`,
    data,
  });
};

const generateInvoice = (data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.generateInvoice}`,
    data,
  });
};

const getPhoneNumbers = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getPhoneNumbers}/${caseId}`,
  });
};

const getCaseDetailsForInvoice = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getCaseDetailsForInvoice}/${caseId}`,
  });
};

const dailyCauseList = () => {
  return API({
    method: "GET",
    url: `${config.urls.case.dailyCauseList}`,
  });
};

const pushCaseToArbitration = (caseId) => {
  return API({
    method: "POST",
    url: `${config.urls.case.pushCaseToArbitration}/${caseId}`,
  });
};

const updateCaseManagerCases = (id, data) => {
  return API({
    method: "PUT",
    url: config.urls.case.index + `/${id}`,
    data,
  });
};

const reOpenCaseCM = (id, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.reOpenCaseCM}/${id}`,
    data,
  });
};

const generateInvoiceCase = (caseId, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.generateInvoiceCase}/${caseId}`,
    data,
  });
};

const generateRTMPLink = (meetingId) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.generateRTMPLink}/${meetingId}`,
  });
};

const casesForMIS = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.casesForMIS + query,
  });
};

const downloadMIS = (data) => {
  return API({
    method: "POST",
    url: config.urls.case.downloadMIS,
    data,
  });
};

const getMeetingDet = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.mettingcase}/${id}`,
  });
};

const bulkNextHearingDate = (data) => {
  return API({
    method: "PUT",
    url: config.urls.case.index,
    data,
  });
};

const bulkUploadDocuments = (data) => {
  return API({
    method: "POST",
    url: config.urls.case.bulkUploadDocuments,
    data,
  });
};

const getBulkDownloadDocumentDet = (query) => {
  return API({
    method: "GET",
    url: config.urls.bulkdownload.getBulkDownloadDocumentDet + query,
  });
};

const bulkDownload = (data) => {
  return API({
    method: "POST",
    url: config.urls.bulkdownload.bulkDownloadDocuments,
    data,
  });
};

const bulkUpdates = (data) => {
  return API({
    method: "POST",
    url: config.urls.bulkdownload.bulkUpdates,
    data,
  });
};

export default {
  index,
  stats,
  uploads,
  saveSummary,
  uploadAgreement,
  uploadStats,
  caseDocumentListing,
  caseAddDocument,
  negotiations,
  getCase,
  getMeeting,
  downloadCaseAgreement,
  getcaseDocuments,
  caseStats,
  getPendingActions,
  sendRemainder,
  offlinePayment,
  updateAgreement,
  assignMediator,
  updateCase,
  mediations,
  sendComment,
  mediationSuccess,
  quitMediation,
  removeMediator,
  getPayments,
  deleteDocument,
  refundPayment,
  bulkUpload,
  getOrders,
  getNotificationCount,
  clearNotification,
  quitNegotiation,
  deleteDraftCase,
  deleteSelectedCases,
  addAllCases,
  removeAllCases,
  selectCaseItem,
  removeCaseItem,
  updateComment,
  deleteComment,
  getPartyListByCaseId,
  addNotes,
  getNotes,
  updateCaseEmail,
  getGuestUserMeeting,
  getMeetingVideo,
  changeDocType,
  addTranscript,
  updateTranscript,
  deleteTranscriptDoc,
  nextHearingDate,
  getRefundHistory,
  updateClaimDescription,
  updateCaseReopen,
  joinMeeting,
  getRecordings,
  getMeetingLink,
  getGenericMeeting,
  getTranscriptionData,
  transcriptionControlCM,
  GetMeetingcases,
  getLiveTranscription,
  enableLiveTranscription,
  getLiveTranscriptionData,
  enableLiveTranscriptionCommon,
  getLiveTranscriptionDataGenric,
  updateLiveTranscriptionData,
  updateTranscriptionData,
  genricStopTranscription,
  stopTranscription,
  transcriptionSettings,
  publishTranscripts,
  presidingArbitrator,
  startTranscription,
  GetMeetingAccesscases,
  transcriptionControl,
  caseTranscriptionSettings,
  RecordingControl,
  DisableEnableMeeting,
  genricstartTranscription,
  getAudioDetails,
  getSplitAudio,
  getLiveTranscriptDet,
  getTranscriptionStatus,
  moveTranscriptToDoc,
  downloadComments,
  getBundleName,
  downloadTranscripts,
  getTranscribersList,
  assignTranscribers,
  getMeetingAudioTranscript,
  updateAudioTranscriptData,
  mergeAudioTranscriptData,
  NeutralBulkCase,
  getCasesDetails,
  getCaseMeetingDetails,
  updateMeetingDetails,
  getExcelFields,
  bulkNoticeGenerate,
  postTranscriptionData,
  getTranscriptionDataCrossAndRunning,
  updateXExamTranscript,
  updateXExamTranscriptDoc,
  moveTranscriptToRecordings,
  selectMeetingAudioDetails,
  getPassiveNeutral,
  getIndividualUser,
  disableEnableNeutral,
  createPassiveNeutral,
  updatePassiveNeutral,
  removePassiveUser,
  updateCaseStatus,
  getDocumentList,
  sendDocument,
  generateInvoice,
  getPhoneNumbers,
  getCaseDetailsForInvoice,
  dailyCauseList,
  pushCaseToArbitration,
  updateCaseManagerCases,
  reOpenCaseCM,
  generateInvoiceCase,
  generateRTMPLink,
  casesForMIS,
  downloadMIS,
  getMeetingDet,
  bulkNextHearingDate,
  bulkUploadDocuments,
  getBulkDownloadDocumentDet,
  bulkDownload,
  bulkUpdates,
};
