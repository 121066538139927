import React, { Fragment, useEffect, useRef, useState } from "react";
import COLORS from "../../../assets/Colors";
import CustomTable from "../../common/CustomTable/CustomTable";
import ActionBar from "../../common/ActionBar";
import _ from "lodash";
import queryString from "query-string";
import labels from "../../../helpers/labels.json";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import styled from "styled-components";
import theme from "../../../assets/theme";
import AuthService from "../../../services/AuthService";
import GenericMeeting from "./GenericMeeting";
import TranscriptionControl from "../TranscriptionControl";
import { navigate } from "@reach/router";
import { PrimaryCTAButtonRecording } from "../../common/Buttons";
import TranscriptionSettings from "../TranscriptionSettings/index";
import AlertDialog from "../../common/Alert";
import useLoader from "../../../hooks/useLoader";
import { Settings } from "@material-ui/icons";

const RecordingsTable = () => {
  const [state, setState] = useState({});
  const MTRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [caseManagerId, setcaseManagerId] = useState();
  const [statusCheck, setStatusCheck] = useState();
  const [modal, setModal] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const { setLoader } = useLoader();
  const [open, setOpen] = useState();

  const getRecordings = async (query) => {
    try {
      const response = await CaseService.getRecordings(query);
      return { ...response };
    } catch (error) {
      throw error;
    }
  };

  // async function getLiveTranscription(transcriptId) {
  //   try {
  //     const transcriptionControl = await CaseService.getLiveTranscription(
  //       transcriptId
  //     );
  //     if (transcriptionControl) {
  //       navigate(`/dashboard/case-manager/recordings/transcription`, {
  //         state: transcriptionControl
  //       });
  //     }
  //   } catch (error) {
  //     const message = getErrorMessage(error);
  //     enqueueSnackbar(message, {
  //       variant: "error"
  //     });
  //   }
  // }

  // async function getLiveTranscriptionDataGeneric() {
  //   try {
  //     const response = await CaseService.getLiveTranscriptionDataGenric();
  //     navigate(`/dashboard/case-manager/recordings/transcription`, {
  //       state: response
  //     });
  //   } catch (error) {
  //     const message = getErrorMessage(error);
  //     enqueueSnackbar(message, {
  //       variant: "error"
  //     });
  //   } finally {
  //   }
  // }

  useEffect(() => {
    async function enableLiveTranscription() {
      try {
        const transcriptionControl =
          await CaseService.enableLiveTranscriptionCommon();
        setStatusCheck(transcriptionControl);
        return;
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    enableLiveTranscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function caseManagerDetails() {
      try {
        const caseManager = await AuthService.profile();
        if (caseManager?.me) {
          setcaseManagerId(caseManager?.me?.id);
        }
        return;
      } catch (error) {}
    }
    caseManagerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function stopTranscription() {
    try {
      setLoader({ state: true, message: "Update Meeting Status..." });
      const stopTranscription = await CaseService.genricStopTranscription();
      if (stopTranscription?.message) {
        enqueueSnackbar(stopTranscription?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
    }
  }

  const stopTranscriptionAlert = () => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "No",
      secondaryBtnText: "Yes",
      clickSecondarybtn: () => stopTranscription(),
      clickPrimaryBtn: () => setOpen(false),
      onDialogClose: () => setOpen(false),
      desc: (
        <>
          After you have stopped this live transcription session your transcript
          will be moved to the case documents section. Please ensure you have
          saved your transcription changes.
          <br />
          <br />
          <b style={{ fontSize: "18px" }}>
            Are you sure you want to stop the transcription
          </b>
          ?
        </>
      ),
      heading: `Stop Transcription`,
      descriptionTextStyle: {
        textAlign: "left",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  async function genricstartTranscription() {
    setLoader({ state: true, message: "Start transcription..." });
    try {
      const res = await CaseService.genricstartTranscription();
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const columns = [
    {
      field: "Id",
      title: "Id",
      sorting: false,
      render: (rowData) => (
        <Bold style={{ paddingLeft: "12px" }}>{rowData?.id}</Bold>
      ),
      headerStyle: {
        paddingLeft: "26px",
      },
    },
    {
      field: "name",
      title: "Recording Name",
      sorting: false,
      render: (rowData) =>
        rowData?.RecordingUrl ? (
          <HyperLink
            onClick={() => {
              window.open(rowData?.RecordingUrl);
            }}
          >
            {rowData?.name}
          </HyperLink>
        ) : (
          <div style={{ paddingLeft: "15px" }}>{"-"}</div>
        ),
    },
    {
      field: "duration",
      title: "Duration",
      sorting: false,
      render: (rowData) =>
        rowData?.duration ? (
          <Bold style={{ paddingLeft: "15px" }}>{rowData?.duration}</Bold>
        ) : (
          <div style={{ paddingLeft: "15px" }}>{"-"}</div>
        ),
    },
    {
      field: "audioFileName",
      title: "Live Transcript",
      sorting: false,
      render: (rowData) =>
        rowData?.audioFileUrl ? (
          <HyperLink
            onClick={() =>
              navigate(
                `/dashboard/case-manager/generic-meeting/transcription`,
                {
                  state: rowData,
                },
              )
            }
          >
            {rowData?.audioFileName}
          </HyperLink>
        ) : (
          <div style={{ paddingLeft: "15px" }}>{"-"}</div>
        ),
    },
    {
      field: "totalConfidence",
      title: "Total Confidence",
      sorting: false,
      render: (rowData) =>
        rowData?.totalConfidence ? (
          <Bold style={{ marginLeft: 30 }}>{rowData?.duration}</Bold>
        ) : (
          <div style={{ paddingLeft: "15px" }}>{"-"}</div>
        ),
    },
    {
      field: "time",
      title: "Created Time",
      sorting: false,
      render: (rowData) =>
        rowData?.time ? (
          <Bold>{rowData?.time}</Bold>
        ) : (
          <div style={{ paddingLeft: "15px" }}>{"-"}</div>
        ),
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (query.search) {
        params.search = query.search;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getRecordings(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <>
      <Fragment>
        <ActionBar breadcrumbs={[_.startCase(labels.generic_meeting_link)]} />
        <div style={{ alignItems: "center" }}>
          <PaddingMeeting>
            <GenericMeeting caseManagerId={caseManagerId} />
          </PaddingMeeting>
          <div style={{ display: "flex", alignItems: "center" }}>
            <PaddingMeetingStatus>
              <ContainerCard>
                <TranscriptionControl
                  statusCheck={statusCheck}
                  setStatusCheck={setStatusCheck}
                />
              </ContainerCard>
            </PaddingMeetingStatus>
            {statusCheck?.enableTranscription === "yes" ? (
              <PaddingMeetingStatusCheck style={{ margin: 0, marginLeft: 20 }}>
                <ContainerCard>
                  <PrimaryCTAButtonRecording
                    onClick={() =>
                      navigate(
                        `/dashboard/case-manager/generic-meeting/transcription`,
                        {
                          state: {
                            value: true,
                          },
                        },
                      )
                    }
                  >
                    Live Transcription
                  </PrimaryCTAButtonRecording>
                </ContainerCard>
              </PaddingMeetingStatusCheck>
            ) : (
              ""
            )}
            <PaddingMeetingStatusCheck style={{ margin: 0, marginLeft: 20 }}>
              <ContainerCard>
                <PrimaryCTAButtonRecording
                  onClick={() => stopTranscriptionAlert()}
                >
                  {"Stop Transcription"}
                </PrimaryCTAButtonRecording>
              </ContainerCard>
            </PaddingMeetingStatusCheck>
          </div>
          <div style={{ display: "flex" }}>
            <PaddingMeetingStatus>
              <ContainerCard>
                <PrimaryCTAButtonRecording
                  onClick={() => genricstartTranscription()}
                >
                  {"Start Transcription"}
                </PrimaryCTAButtonRecording>
              </ContainerCard>
            </PaddingMeetingStatus>
            <SettingPaddingMeetingStatus>
              <Container>
                <Settings
                  style={{ fill: COLORS.BTN_GREEN, cursor: "pointer" }}
                  onClick={() => setModal(true)}
                />{" "}
                {"Transcription Setting"}
              </Container>
              {/* <HyperLink onClick={() => setModal(true)}>
                {"Transcription Setting"}
              </HyperLink> */}
            </SettingPaddingMeetingStatus>
          </div>
        </div>
        <ActionBar breadcrumbs={[_.startCase(labels.recordings)]} />
        <Padding>
          <CustomTable
            hidePagination={state.lastPage === 1}
            pageSize={state?.data?.length ? state?.data?.length : 10}
            pluralTitle={labels.recordings}
            hideFilterBar
            singularTitle=""
            {...{
              columns,
              data,
              MTRef,
            }}
            noToolbar
            state={state}
          />
        </Padding>
      </Fragment>
      <TranscriptionSettings {...{ modal, setModal }} />
      <AlertDialog
        isOpen={open}
        {...{ ...dialogData }}
        descriptionTextStyle={{
          textAlign: "left",
          fontFamily: theme.fonts.primaryFontSemiBold,
        }}
      />
    </>
  );
};

export default RecordingsTable;

const Padding = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-right: 53px;
`;

const PaddingMeeting = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 35px;
  padding-right: 53px;
`;

const PaddingMeetingStatus = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 35px;
  // padding-right: 53px;
`;

const SettingPaddingMeetingStatus = styled.div`
  padding-top: 40px;
  padding-bottom: 10px;
  padding-left: 35px;
  // padding-right: 53px;
`;

const PaddingMeetingStatusCheck = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 0px;
  // padding-right: 33px;
`;

const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  font-size: 14px;
`;

const Bold = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const ContainerCard = styled.div`
  width: 265px;
  height: 57px;
  border-radius: 7px;
  border: solid 0.5px #e1e3ee;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px 18px 16px;
`;

export const Container = styled.div`
  display: flex;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;
