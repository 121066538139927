import React, { Fragment, useState, useRef } from "react";
import {
  PaymentContainer,
  TableWrapper,
  HyperLink,
  StyledAmount,
  Center,
  TopCenter,
} from "./Styles.js";
import _ from "lodash";
import queryString from "query-string";
import CustomTable from "../../common/CustomTable/CustomTable";
import CaseService from "../../../services/CaseService";
import moment from "moment";
import {
  getErrorMessage,
  getFileName,
  numberFormat,
} from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import labels from "../../../helpers/labels.json";
import { navigate } from "@reach/router";

async function getPayments(query = "") {
  try {
    const response = await CaseService.getOrders(query);
    return { ...response };
  } catch (error) {
    throw error;
  }
}

const Index = () => {
  const [state, setState] = useState({});
  const MTRef = useRef();
  const placeholderText = "Search Payment";
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      field: "caseId",
      title: "Case ID",
      sorting: false,
      render: (rowData) =>
        rowData?.caseId === "Multiple" ? (
          <HyperLink>
            <TopCenter>{rowData?.caseId}</TopCenter>
          </HyperLink>
        ) : (
          <HyperLink
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(
                `/dashboard/case-manager/${rowData?.resolutionKind}/${rowData?.caseId}`,
              )
            }
          >
            <TopCenter>{rowData?.caseId}</TopCenter>
          </HyperLink>
        ),
      cellStyle: {
        paddingLeft: 12,
      },
    },
    {
      field: "id",
      title: labels["table.order_id"],
      tooltip: "Payment order identifier",
      sorting: true,
      render: (rowData) => (
        <HyperLink>
          <TopCenter>{rowData?.id}</TopCenter>
        </HyperLink>
      ),
      cellStyle: {
        paddingLeft: 32,
      },
    },
    {
      field: "id",
      title: labels["table.invoice_id"],
      sorting: true,
      render: (rowData) => (
        <HyperLink
          style={{
            textDecoration: "none",
            cursor: "inherit",
          }}
        >
          {rowData?.invoiceNumber
            ? `INV${rowData?.invoiceNumber}`
            : `INV${rowData?.id}`}
        </HyperLink>
      ),
      cellStyle: {
        paddingLeft: 32,
      },
    },
    {
      field: "parties.name",
      title: labels["table.party_name"],
      sorting: false,
      render: (rowData) => rowData?.party?.name,
    },
    {
      field: "amount",
      title: labels["table.amount"],
      sorting: false,
      render: (rowData) => (
        <StyledAmount>
          {numberFormat(
            parseFloat(rowData?.amount?.toFixed(2)),
            rowData?.cartItems[0].fee.unit,
          )}
        </StyledAmount>
      ),
    },
    {
      field: "created_at",
      title: labels["table.date"],
      tooltip: "Date of transaction",
      sorting: true,
      render: (rowData) => (
        <>
          {moment(rowData?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY",
          )}
        </>
      ),
    },
    {
      field: "invoiceUrl",
      title: labels["table.invoice"],
      tooltip: "Invoice of the transaction ",
      sorting: false,

      render: (rowData) =>
        rowData?.invoiceUrl ? (
          <HyperLink
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => window.open(rowData?.invoiceUrl)}
          >
            {rowData?.invoiceUrl
              ? getFileName(rowData?.invoiceUrl)?.split(".pdf")[0]
              : "Download"}
          </HyperLink>
        ) : (
          <>
            {rowData?.proformaInvoiceUrl ? (
              <HyperLink
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => window.open(rowData?.proformaInvoiceUrl)}
              >
                {rowData?.proformaInvoiceUrl
                  ? getFileName(rowData?.proformaInvoiceUrl)?.split(".pdf")[0]
                  : "Download"}
              </HyperLink>
            ) : (
              <Center>-</Center>
            )}
          </>
        ),
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
        filter: "status",
      };
      if (query.search) {
        params.query = query.search;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      const filterSting = queryString.stringify(
        { filterValues: ["captured", "capturedOffline"] },
        { arrayFormat: "index" },
      );
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}&${filterSting}`;
      }
      getPayments(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <Fragment>
      <PaymentContainer className="CreateCase" style={{ overflow: "hidden" }}>
        <TableWrapper>
          <CustomTable
            hidePagination={state.lastPage === 1}
            pageSize={state?.data?.length ? state?.data?.length : 10}
            pluralTitle={labels.payments}
            singularTitle=""
            {...{
              columns,
              data,
              placeholderText,
              MTRef,
            }}
            //noToolbar
            hideFilterBar
            state={state}
          />
        </TableWrapper>
      </PaymentContainer>
    </Fragment>
  );
};
export default Index;
