import React, { Fragment, useState, useEffect, useRef } from "react";
import ActionBar from "../common/ActionBar";
import { PageWrapper, StyledLabel } from "./styles";
import LifeCycle from "../common/LifeCycle/LifeCycle";
import CaseDetailsCard from "../common/CaseDetails";
import CaseManager from "./common/CaseManager";
import BigBlueButton from "./common/BigBlueButton";
import _ from "lodash";
import { navigate } from "@reach/router";
import CaseService from "../../services/CaseService";
import useLoader from "../../hooks/useLoader";
import {
  RESPONDANT_ONBOARDED,
  CLAIMENT_STR,
  CLAIMANT_LIFE_CYCLE,
  NEGOTIATION_REACHED,
  NEGOTIATION_QUIT_BY_CLAIMENT,
  NEGOTIATION_QUIT_BY_RESPONDENT,
  NEGOTIATION_QUIT_BY_CASEMANAGER,
  CLAIMENT_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  BOTH_PAID_NEGOTIATION,
  AWAITING_RESPONDANT_QUIT,
  LIFE_CYCLE,
} from "../../helpers/constants";
import { getErrorMessage } from "../../helpers/functions";
import { useSnackbar } from "notistack";
import DrawerTable from "../internal/DrawerTable";
import MediationState from "./MediationState";
import theme from "../../assets/theme";
import AlertDialog from "../common/Alert";
import queryString from "query-string";
import useNotification from "../../hooks/useNotification";
import { CaseManagerCardData } from "./styles";
import { PrimaryCTAButton } from "../common/Buttons";
import TranscriptionSettings from "../internal/TranscriptionSettingsCases";
import { HyperLink } from "../../styles/component/style";
import COLORS from "../../assets/Colors";
import styled from "styled-components";
import CaseDetailsStatus from "../internal/CaseDetailsStatus";
import ExtraFieldsDrawer from "../internal/DrawerTable/ExtraFieldsDrawer.js";

export default function CaseDetails({ id }) {
  const initialState = ["Cases"];
  const [state, setState] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState(initialState); // This state is to display the bread crum
  const { setLoader } = useLoader(); // This Hook is to display the loader
  const [caseDetails, setCaseDetails] = useState({}); // This state holds the case detaisl
  const [caesLifeCycle, setCaseLifeCyle] = useState([]); // This state holds the case lifecycle
  const [isRespondent, setIsRespondent] = useState(false); // This state tells current logged in user is respondent or not
  const [ownerId, setOwnerId] = useState(); // this state holds the ownerId
  const [respondentId, setRespondentId] = useState(); // THis state holds the respondent Id
  const { enqueueSnackbar } = useSnackbar(); // This Hook is used to trigger the snackbar message
  const [negotiationRounds, setNegotiationRounds] = useState({}); // THis state holds the NegotiationRounds
  const [currentState, setCurrentState] = useState({}); // This state Holds the current state
  const MTRef = useRef();
  const [pendingRegistrationFee, setPendingRegistrationFee] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(true);
  const [comments, setComments] = useState([]); // This is list of comments
  const [dialogData, setDialogData] = useState({}); // This state is used to set the dialog content
  const [open, setOpen] = useState(false); //This state is for open and Close the model
  const [partyList, setPartyList] = useState([]);
  const { triggerNotify, setTriggerNotify } = useNotification(); // hooks to refresh the page
  const [settingsModal, setSettingsModal] = useState(false);
  const [meetingDetail, setMeetingDetail] = useState();
  const [statusModal, setStatusModal] = useState(false);
  const [docUploadModal, setDocUploadModal] = useState();
  const [fields, setFields] = useState(false);

  useEffect(() => {
    if (triggerNotify) {
      setRefreshDetails(true);
      setTriggerNotify(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerNotify]);

  /**THis useEffect is Used to get the case information */

  useEffect(() => {
    /** Function to get the CaseDetails */
    async function getCaseById(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        const res = await CaseService.getCase(id);
        if (res) {
          let case_response = { ...res };
          if (case_response.status !== "draft") {
            if (case_response.status === "pendingRegistrationFee") {
              setPendingRegistrationFee(true);
            }
            if (!case_response.respondentPartyId) {
              case_response.status = res?.status;
            } else if (
              case_response.respondentStatus === "pending" ||
              case_response.respondentStatus === "declined"
            ) {
              case_response.status = RESPONDANT_ONBOARDED;
            } else if (
              case_response?.status === NEGOTIATION_REACHED &&
              case_response?.subscriptionKind === "adhoc"
            ) {
              case_response.status = BOTH_PAID_NEGOTIATION;
            }
          }
          if (case_response?.resolutionKind === "negotiation") {
            getCaseNegotiations(id);
          } else {
            getCaseMediation(id);
          }
          getparty(id);
          setCaseDetails(case_response);
          setBreadcrumbs([
            "Cases",
            _.startCase(case_response?.resolutionKind),
            case_response.title,
          ]);
          setCaseLifeCyle(
            case_response?.resolutionKind !== "negotiation"
              ? JSON.parse(JSON.stringify(CLAIMANT_LIFE_CYCLE))
              : JSON.parse(JSON.stringify(LIFE_CYCLE)),
          );
          setIsRespondent(case_response.agentRole !== CLAIMENT_STR);
          setOwnerId(case_response.claimantPartyId);
          setRespondentId(case_response.respondentPartyId);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        window.scrollTo(0, 0);
      }
    }

    async function getparty(id) {
      let params = {
        page: 1,
        perPage: 1000,
      };
      const partyParam = `?${queryString.stringify(
        params,
      )}&sort=partyRole&sortDirection=asc`;
      try {
        const party_list_res = await CaseService.getPartyListByCaseId(
          id,
          partyParam,
        ); // This is for getting the party details based on the case
        if (party_list_res?.data) {
          setPartyList(party_list_res?.data);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }

    /** Function to get the negotiation round Details */
    async function getCaseMediation(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        let params = {
          page: 1,
          perPage: 1000,
        };
        let stringParams = "";
        if (!_.isEmpty(params)) {
          stringParams = `?${queryString.stringify(params)}`;
        }
        const mediation_res = await CaseService.mediations(id, stringParams);
        if (mediation_res) {
          setComments(mediation_res?.data);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }

    /** Function to get the negotiation round Details */
    async function getCaseNegotiations(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        const case_response = await CaseService.negotiations(id);
        setNegotiationRounds(case_response);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (id && refreshDetails) {
      getCaseById(id);
      setRefreshDetails(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDetails]);

  /** THis is useEffect is used to set the curretState based on the status **/
  useEffect(() => {
    if (caesLifeCycle.length && !_.isEmpty(caseDetails)) {
      if (caesLifeCycle.some((el) => el.key === caseDetails.status)) {
        setCurrentState(
          caesLifeCycle.find((el) => el.key === caseDetails.status),
        );
      } else if (!isRespondent) {
        if (caseDetails.respondentId) {
          setCurrentState(caesLifeCycle.find((el) => el.index === 2));
        } else {
          setCurrentState(caesLifeCycle.find((el) => el.index === 1));
        }
      } else if (caseDetails.status === "finalAward") {
        setCurrentState({
          label: "Final Award",
          key: "finalAward",
          index: 5,
          enableSelection: false,
          cta: [],
        });
      }
    }
  }, [caesLifeCycle, caseDetails, isRespondent]);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case _.snakeCase(caseDetails?.resolutionKind):
        navigate(`/dashboard/case-manager/${caseDetails?.resolutionKind}`);
        break;
      case "cases":
        navigate(`/dashboard/case-manager/${caseDetails?.resolutionKind}`);
        break;
      default:
        break;
    }
  };

  const joinMeetingAlert = (id, resolutionKind) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Proceed",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => setOpen(false),
      clickPrimaryBtn: () => getMeetingjoin(id),
      onDialogClose: () => setOpen(false),
      desc: (
        <>
          <b style={{ color: COLORS.LOGOUT_RED }}>
            We may record the meeting for case proceedings and transcription
            purposes
          </b>
        </>
      ),
      heading: `Join ${_.capitalize(resolutionKind)} Session`,
      descriptionTextStyle: {
        textAlign: "left",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  async function getMeetingjoin(id) {
    try {
      const meetingurl = await CaseService.getMeeting(id);
      window.open(meetingurl);
      return;
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setOpen(false);
    }
  }

  return (
    <Fragment>
      <ActionBar
        breadcrumbs={breadcrumbs}
        onBreadcrumbClick={onBreadcrumbClick}
      />
      <PageWrapper>
        <div>
          <CaseDetailsCard
            isActionsDisabled={
              currentState?.key === NEGOTIATION_REACHED ||
              currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
              currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER ||
              currentState?.key === CLAIMENT_PAID_NEGOTIATION ||
              currentState?.key === RESPONDENT_PAID_NEGOTIATION ||
              currentState?.key === BOTH_PAID_NEGOTIATION ||
              currentState?.key === AWAITING_RESPONDANT_QUIT ||
              currentState?.key === "finalAward"
            }
            {...{ setState }}
            viewOnly={true}
            disableRefund={
              pendingRegistrationFee || caseDetails?.status === "draft"
            }
            caseDetails={caseDetails}
            refreshDetails={refreshDetails}
            pendingRegistrationFee={pendingRegistrationFee}
            {...{
              setState,
              id,
              MTRef,
              partyList,
              docUploadModal,
              setDocUploadModal,
              setFields,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="flex wrap">
            {caseDetails?.resolutionKind !== "negotiation" ? (
              <>
                {caseDetails?.requestedMediators?.length ? (
                  <>
                    {caseDetails?.requestedMediators?.map((mediator, idx) => (
                      <div className="mr25 mt28" key={idx}>
                        <CaseManager
                          title={"Arbitrator"}
                          hideChat={true}
                          caseManager={mediator}
                        />
                      </div>
                    ))}
                  </>
                ) : null}
              </>
            ) : null}
            <div className="mr25 mt28">
              {caseDetails?.resolutionKind !== "negotiation" && (
                <BigBlueButton
                  onClick={() =>
                    joinMeetingAlert(id, caseDetails?.resolutionKind)
                  }
                  type={`Join ${_.capitalize(
                    caseDetails?.resolutionKind,
                  )} Session`}
                />
              )}
            </div>
            {meetingDetail?.recordingStatus === "yes" &&
            meetingDetail?.transcriptionStatus === "yes" &&
            meetingDetail?.status === "open" ? (
              <div className="mr25 mt28">
                {caseDetails?.resolutionKind !== "negotiation" && (
                  <CaseManagerCardData>
                    <PrimaryCTAButton
                      style={{ width: "295px" }}
                      onClick={() =>
                        navigate(
                          meetingDetail?.crossExamination
                            ? `/dashboard/case-manager/${caseDetails?.resolutionKind}/${id}/cross-examination`
                            : `/dashboard/case-manager/${caseDetails?.resolutionKind}/${id}/livetranscription`,
                          {
                            state: meetingDetail?.crossExamination
                              ? meetingDetail
                              : caseDetails,
                          },
                        )
                      }
                    >
                      {meetingDetail?.crossExamination
                        ? "Cross Examination"
                        : "Live Transcription"}
                    </PrimaryCTAButton>
                  </CaseManagerCardData>
                )}
              </div>
            ) : (
              ""
            )}
            <div className="mr25 mt52">
              {caseDetails?.resolutionKind !== "negotiation" &&
                caseDetails?.status !== "draft" && (
                  <HyperLink onClick={() => setSettingsModal(true)}>
                    {"Transcription Settings"}
                  </HyperLink>
                )}
            </div>
          </div>
        </div>
        {caseDetails?.status !== "draft" && (
          <>
            <div style={{ marginTop: 28 }}>
              <StyledLabel>Case Lifecycle</StyledLabel>
              <LifeCycle
                items={caesLifeCycle}
                resolutionKind={caseDetails?.resolutionKind}
                selectedIndex={currentState?.index}
                isMediation={caseDetails?.resolutionKind !== "negotiation"}
                status={currentState?.text}
                currentStateKey={currentState?.key}
                viewOnly={true}
              />
            </div>
            <div style={{ marginTop: 28 }}>
              <MediationState
                {...{
                  currentState,
                  negotiationRounds,
                  isRespondent,
                  ownerId,
                  respondentId,
                  caseDetails,
                  comments,
                  setComments,
                  partyList,
                }}
                isLocalTranscriptionServer={
                  meetingDetail?.isLocalTranscriptionServer
                }
              />
            </div>
            {currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER ||
            currentState?.key === AWAITING_RESPONDANT_QUIT ? (
              <QuitContainer
                style={{
                  marginTop: 30,
                }}
              >
                <div>
                  <QuitLabel>Reason for Closing</QuitLabel>
                </div>
                <QuitContents>{caseDetails?.reasonForClose}</QuitContents>
              </QuitContainer>
            ) : null}
            {currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER ||
            currentState?.key === AWAITING_RESPONDANT_QUIT ? (
              <QuitContainer
                style={{
                  marginTop: 30,
                }}
              >
                <div>
                  <QuitLabel>Date of Close</QuitLabel>
                </div>
                <QuitContents>{caseDetails?.dateofClose}</QuitContents>
              </QuitContainer>
            ) : null}
          </>
        )}
      </PageWrapper>

      <div>
        <AlertDialog
          isOpen={open}
          {...{ ...dialogData }}
          descriptionTextStyle={{
            textAlign: "left",
            fontFamily: theme.fonts.primaryFontSemiBold,
          }}
        />
      </div>

      <DrawerTable
        {...{
          state,
          setState,
          id,
          MTRef,
          docUploadModal,
          caseDetails,
        }}
        viewOnly={true}
        title={caseDetails?.title}
        type={caseDetails?.resolutionKind}
        claimantId={caseDetails?.claimantParty?.ownerId}
        respondentId={caseDetails?.respondentParty?.ownerId}
        agreementsAndAwards={caseDetails?.agreementsAndAwards}
        isActionsDisabled={
          currentState?.key === NEGOTIATION_REACHED ||
          currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
          currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
          currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER ||
          currentState?.key === CLAIMENT_PAID_NEGOTIATION ||
          currentState?.key === RESPONDENT_PAID_NEGOTIATION ||
          currentState?.key === BOTH_PAID_NEGOTIATION ||
          currentState?.key === AWAITING_RESPONDANT_QUIT ||
          currentState?.key === "finalAward"
        }
      />
      <ExtraFieldsDrawer {...{ fields, setFields, caseDetails, id }} />

      <TranscriptionSettings
        viewOnly={true}
        modal={settingsModal}
        setModal={setSettingsModal}
        {...{ meetingDetail, setMeetingDetail, id }}
      />

      <CaseDetailsStatus
        currentState={currentState}
        {...{ statusModal, setStatusModal, setRefreshDetails, id, caseDetails }}
      />
    </Fragment>
  );
}

export const ReasonForQuit = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 16px;
  color: ${COLORS.LOGOUT_RED};
`;

export const ReasonForQuitText = styled.p`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
  margin-left: 4px;
`;

export const QuitContainer = styled.div`
  padding: 18px;
  border-radius: 7px;
  background-color: ${COLORS.LIGHT_BLUE};
  border: solid 1px ${COLORS.COLOR_DARK};
  margin-bottom: 12px;
  max-width: 80%;
`;

export const QuitLabel = styled.div`
  color: ${COLORS.LOGOUT_RED};
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
`;

export const QuitContents = styled.div`
  font-size: 14px;
  word-break: break-word;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-top: 18px;
  & strong,
  & bold {
    font-family: ${theme.fonts.primaryFontExtraBold};
  }
`;
