import React, { useState, useRef, useEffect } from "react";
import ActionBar from "../../common/ActionBar";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import CustomTable from "../../common/CustomTable/CustomTable";
import moment from "moment";
import queryString from "query-string";
import SystemService from "../../../services/SystemService";
import _ from "lodash";
import { navigate } from "@reach/router";
import ProfileModal from "../../pages/ProfileDetails/components/ProfileModal";
import labels from "../../../helpers/labels.json";
import { parseTimeStamp } from "../../../helpers/functions";
import usePrevious from "../../../hooks/usePrevious";

const initFilters = [
  {
    label: "User",
    key: "userCount",
    value: 0,
  },
  {
    label: "Neutral",
    key: "neutralCount",
    value: 0,
  },
  {
    label: "View Only User",
    key: "viewOnlyUserCount",
    value: 0,
  },
];

const filterByStatus = (selectedFilter) => {
  let key = "status";
  let value = "";
  let passive = "";
  switch (selectedFilter) {
    case "User":
      value = "agent";
      passive = "";
      break;
    case "Neutral":
      value = "mediator";
      passive = "";
      break;
    case "View Only User":
      value = "mediator";
      passive = "passive";
      break;
    default:
      break;
  }
  return { key, value, passive };
};

async function getProfiles(query = "") {
  try {
    const response = await SystemService.getProfiles(query);
    const stats = await SystemService.getUsersCount();
    return { ...response, stats };
  } catch (error) {
    return error;
  }
}

const Index = () => {
  const [state, setState] = useState({});
  const placeholderText = labels.search_profiles;
  const MTRef = useRef();
  const [modal, setModal] = useState();
  const [user, setUser] = useState();
  const breadcrumbs = [_.startCase(labels.profiles)];
  const [filters, setFilters] = useState(initFilters);
  const [selectedFilter, setSelectedFilter] = useState(filters[0].label);
  const filterPreviousValue = usePrevious(filters);
  const { passive } = filterByStatus(selectedFilter);

  function setStats(stats) {
    const mapped = initFilters.map((filter) => {
      let returnData = {
        label: filter.label,
        value: filter.value,
      };
      if (stats[filter.key] >= 0) {
        returnData.value = stats[filter.key];
      }
      return returnData;
    });
    setFilters(mapped);
  }

  useEffect(() => {
    if (selectedFilter && filterPreviousValue) {
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  const actions = [
    {
      text: "Add view only user",
      props: {
        onClick: () =>
          navigate(`/dashboard/case-manager/profiles/create-view-only-user`),
      },
    },
  ];

  const columns = [
    {
      field: "id",
      title: labels.profile_id,
      tooltip: "Unique Identifier for the Profile across JustAct platform",
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(
              passive
                ? `/dashboard/case-manager/profiles/view-only-user/${rowData.id}`
                : `/dashboard/case-manager/profiles/${rowData.id}`,
            )
          }
        >
          {rowData.id}
        </HyperLink>
      ),
    },
    {
      field: "name",
      title: labels.profile_name,
      render: (rowData) => (
        <Bold
          onClick={() =>
            navigate(
              passive
                ? `/dashboard/case-manager/profiles/view-only-user/${rowData.id}`
                : "/dashboard/case-manager/profiles/" + rowData.id,
            )
          }
        >
          {rowData.name}
        </Bold>
      ),
      sorting: false,
    },
    {
      field: "email",
      title: labels.profile_email,
      tooltip: "Email associated with the Profile",
      sorting: false,
      headerStyle: {
        maxWidth: "120px !important",
        width: "120px !important",
      },
      cellStyle: {
        maxWidth: "120px !important",
        width: "120px !important",
      },
    },

    {
      field: "created_at",
      title: labels.created_on,
      tooltip: "Profile Created Date",
      render: (rowData) => (
        <>{moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY")}</>
      ),
      headerStyle: {
        maxWidth: "120px !important",
        width: "120px !important",
      },
      cellStyle: {
        maxWidth: "120px !important",
        width: "120px !important",
      },
    },
    {
      field: "action",
      title: labels.actions.substring(0, labels.actions.length - 1),
      sorting: false,
      render: (rowData) =>
        passive ? (
          <HyperLink
            onClick={() =>
              navigate(`/dashboard/case-manager/profiles/edit/${rowData.id}`)
            }
          >
            {labels.edit_profile}
          </HyperLink>
        ) : (
          <HyperLink
            onClick={() => {
              setUser(rowData);
              setModal(true);
            }}
          >
            {labels.edit_profile}
          </HyperLink>
        ),
      tooltip: "Option to edit user profile",
      headerStyle: {
        maxWidth: "120px !important",
        width: "120px !important",
      },
      cellStyle: {
        maxWidth: "120px !important",
        width: "120px !important",
      },
    },
  ];

  const data = (query) =>
    new Promise((resolve, reject) => {
      const { value, passive } = filterByStatus(selectedFilter);
      let params = {
        page: query.page + 1,
        perPage: 10,
        role: value,
        type: passive,
      };
      if (query.search) {
        params.query = query.search;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getProfiles(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
            setStats(result.stats);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  return (
    <div className="myProfiles">
      <ActionBar
        // outlined
        breadcrumbs={breadcrumbs}
        actions={passive ? actions : []}
      />
      <Padding>
        <CustomTable
          hidePagination={state.lastPage === 1}
          pageSize={state?.data?.length ? state?.data?.length : 10}
          pluralTitle={_.startCase(labels.profiles)}
          singularTitle=""
          onFilterClick={() => setModal(true)}
          {...{
            columns,
            data,
            placeholderText,
            MTRef,
            filters,
            selectedFilter,
            setSelectedFilter,
          }}
          // noToolbar
          // hideFilterBar
          state={state}
        />
        <ProfileModal
          setUser={() => {
            setUser({});
            MTRef.current.onQueryChange();
          }}
          {...{ modal, setModal, user }}
        />
      </Padding>
    </div>
  );
};

export default Index;

const Padding = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-right: 53px;
`;
const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  padding-left: 29px;
`;
const Bold = styled.span`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;
