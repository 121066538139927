import useUser from "./useUser";
import { useEffect } from "react";
import { navigate } from "@reach/router";

export default function useIfLoggedIn() {
  const { token, setToken } = useUser();
  useEffect(() => {
    const store = localStorage.getItem("auth_token");
    if (token && store) {
      navigate("/dashboard/case-manager");
    } else {
      setToken(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
}
