import React, { Fragment, useRef, useState } from "react";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import CustomTable from "../../common/CustomTable/CustomTable";
import ActionBar from "../../common/ActionBar";
import _ from "lodash";
import queryString from "query-string";
import labels from "../../../helpers/labels.json";
import { useSnackbar } from "notistack";
import { getErrorMessage, parseTimeStamp } from "../../../helpers/functions";
import TranscriberService from "../../../services/TranscriberService";
import styled from "styled-components";
import moment from "moment";
import { navigate } from "@reach/router";

async function getTranscriberList(query = "") {
  try {
    const response = await TranscriberService.getTranscribers(query);
    return { ...response };
  } catch (error) {
    throw error;
  }
}

const TranscriberTable = () => {
  const breadcrumbs = [_.startCase(labels.transcriber)];
  const [state, setState] = useState({});
  const MTRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const placeholderText = "Search Transcriber";
  const actions = [
    {
      text: labels["add_transcriber"],
      props: {
        onClick: () =>
          navigate(`/dashboard/case-manager/transcriber/create-transcriber`),
      },
    },
  ];

  const columns = [
    {
      field: "id",
      title: labels["id_type"],
      sorting: true,
      tooltip: "Unique Identifier for the Case manager across JustAct platform",
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(`/dashboard/case-manager/transcriber/${rowData?.id}`)
          }
        >
          {rowData?.id}
        </HyperLink>
      ),
      headerStyle: {
        paddingLeft: "40px",
      },
    },
    {
      field: "name",
      title: labels["profile_name"],
      sorting: false,
      render: (rowData) => (
        <Bold
          onClick={() =>
            navigate(`/dashboard/case-manager/transcriber/${rowData?.id}`)
          }
        >
          {rowData?.name}
        </Bold>
      ),
    },
    {
      field: "email",
      tooltip: "Email associated with the Case manager",
      title: labels["profile_email"],
      sorting: false,
    },
    {
      field: "mobile",
      tooltip: "Phone number associated with the Case manager",
      title: labels["profile_mobile"],
      sorting: false,
      render: (rowData) => <div>{rowData?.mobile ? rowData?.mobile : "-"}</div>,
      cellStyle: {
        maxWidth: 100,
        width: 100,
        paddingLeft: "22px",
      },
    },
    {
      field: "created_at",
      title: labels["created_on"],
      sorting: false,
      render: (rowData) => (
        <>{moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY")}</>
      ),
      cellStyle: {
        maxWidth: 100,
        width: 100,
        paddingLeft: "22px",
      },
    },
    {
      field: "updated_at",
      title: labels["updated_on"],
      sorting: false,
      render: (rowData) => (
        <>{moment(parseTimeStamp(rowData.updated_at)).format("DD/MM/YYYY")}</>
      ),
      cellStyle: {
        maxWidth: 100,
        width: 100,
        paddingLeft: "22px",
      },
    },
    {
      field: "Case Details",
      title: "Case Details",
      sorting: false,
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(`/dashboard/case-manager/transcriber/${rowData?.id}`)
          }
        >
          View
        </HyperLink>
      ),
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      if (query?.search) {
        params.query = query?.search;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getTranscriberList(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <Fragment>
      <ActionBar {...{ breadcrumbs }} {...{ actions }} />
      <Padding>
        <CustomTable
          hidePagination={state.lastPage === 1}
          pageSize={state?.data?.length ? state?.data?.length : 10}
          pluralTitle={labels.case_manager}
          hideFilterBar
          singularTitle=""
          {...{
            columns,
            data,
            MTRef,
            placeholderText,
          }}
          // noToolbar
          // hideFilterBar
          state={state}
        />
      </Padding>
    </Fragment>
  );
};

export default TranscriberTable;

const Padding = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-right: 53px;
`;

const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  padding-left: 29px;
`;

const Bold = styled.span`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;
