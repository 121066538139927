import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Formik } from "formik";
import {
  Modal,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { CustomFileUpload } from "../../common/FormInputs";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import { StyledLabel } from "../../pages/CaseDetails/styles";
import AWSService from "../../../services/AWSService";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage, getFileName } from "../../../helpers/functions";
import axios from "axios";
import { HyperLink } from "../../internal/NoticeGeneration/styles";

const useStyles = makeStyles(() => ({
  formLabel: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
  table: {
    backgroundColor: "transparent",
    border: "1px solid #e1e3ee",
    borderRadius: 7,
    "& .MuiTableCell-root": {
      borderBottom: 0,
    },
  },
}));

const validFormat = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  "application/vnd.ms-excel", // .xls
];

const AddressLabel = ({ addressLabel, setAddressLabel }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file) {
      try {
        const errorMessage = "Invalid File Type";
        if (!validFormat.includes(file?.type)) throw errorMessage;
        setLoader({ state: true, message: `Uploading file: ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
          enqueueSnackbar("Successfully Uploaded", { variant: "success" });
        }
      } catch (error) {
        const message = getErrorMessage(error);
        setFieldError(field, "Could not upload this file");
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  /*

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "please wait..." });
      const payload = {
        documentsDet: {
          excelFile: values?.url,
          excelFileName: getFileName(values?.url),
        },
      };
      console.log(payload, "payload");
      const response = await CaseService.addresslableGenerate(payload);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
    } catch (err) {
      const error = getErrorMessage(err);
      enqueueSnackbar(error, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  */

  const onFormSubmit = async (values) => {
    setLoader({ state: true, message: "please wait..." });
    const payload = {
      documentsDet: {
        excelFile: values?.url,
        addressLabelType: values?.addressLabelType,
        excelFileName: getFileName(values?.url),
      },
    };
    const headers = {
      "content-type": "application/json",
    };
    axios
      .post(`https://staging-api.justact.co.in/addressLabel`, payload, {
        headers,
      })
      .then((res) => {
        window.open(res?.data?.s3_file_url);
        if (res) {
          enqueueSnackbar("Your address label has been generated", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader({ state: false });
        setAddressLabel(false);
      });
  };

  const inputs = [
    {
      label: "(8 * 2)",
      name: "a4st16",
      value: "a4st16",
    },
    {
      label: "(6 * 2)",
      name: "a4st12",
      value: "a4st12",
    },
  ];

  return (
    <Modal open={addressLabel} onClose={() => setAddressLabel(false)}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{"Address Label"}</Heading>
          <CloseModal
            onClick={() => setAddressLabel(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <FormContainer>
          <Formik
            initialValues={{
              url: "",
              addressLabelType: "a4st16",
            }}
            enableReinitialize
            validateOnBlur
            validateOnChange
            onSubmit={onFormSubmit}
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              setFieldValue,
              setFieldError,
              handleBlur,
              isValid,
            }) => (
              <>
                <StyledFormElementsWrapper
                  style={{
                    marginBottom: 3,
                  }}
                >
                  <>
                    <Row
                      style={{
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <StyledLabel>Address Label </StyledLabel>
                    </Row>
                    <Grid item xs={12} spacing={2}>
                      <RadioGroup
                        className={classes.radioGroup}
                        style={{
                          flexDirection: "row",
                          marginLeft: "10px",
                        }}
                        name={"addressLabelType"}
                        value={values.addressLabelType}
                        onChange={handleChange}
                      >
                        {inputs.map((radio, key) => (
                          <FormControlLabel
                            {...{ key }}
                            classes={{ label: classes.formLabel }}
                            value={radio.value}
                            control={
                              <Radio
                                size="medium"
                                style={{ color: "#00838c" }}
                              />
                            }
                            label={radio.label}
                          />
                        ))}
                      </RadioGroup>
                    </Grid>
                    <div style={{ marginBottom: 13 }}>
                      {/* File upload extra field */}
                      <CustomFileUpload
                        setFile={(file) =>
                          s3Upload(file, `url`, setFieldValue, setFieldError)
                        }
                        style={{
                          marginTop: 10,
                          width: "100%",
                        }}
                        accept=".xlsx"
                        file={{ name: getFileName(values?.url) }}
                        fileUrl={values?.url}
                      />
                    </div>
                    <HyperLink
                      onClick={() =>
                        window.open(
                          "https://justact-api-uploads.s3.ap-south-1.amazonaws.com/15/1729664876062-d54c43c7-c768-4740-8b43-32062d65aafd/Address_lable_Template.xlsx",
                        )
                      }
                    >
                      Click here to download the Address Label Template
                    </HyperLink>
                  </>
                </StyledFormElementsWrapper>
                <Flex>
                  <HalfWidth>
                    <PrimaryOutlinedCTAButton
                      onClick={() => setAddressLabel(false)}
                    >
                      {"Cancel"}
                    </PrimaryOutlinedCTAButton>
                  </HalfWidth>

                  <HalfWidth>
                    <PrimaryCTAButton
                      disabled={!values.url}
                      onClick={handleSubmit}
                    >
                      {"Submit"}
                    </PrimaryCTAButton>
                  </HalfWidth>
                </Flex>
              </>
            )}
          </Formik>
        </FormContainer>
      </DrawerContainer>
    </Modal>
  );
};

export default AddressLabel;

const DrawerContainer = styled.div`
  max-width: 95%;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const FormContainer = styled.div`
  padding-top: 14px;
  padding-left: 54px;
  padding-right: 54px;
  padding-bottom: 26px;
  & form {
    padding-top: 10px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 47px;
`;

const HalfWidth = styled.div`
  width: 48%;
`;

const StyledFormElementsWrapper = styled.div`
  margin-bottom: 28px;
  max-width: 436px;
  & .link {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
  }
  & .template {
    margin-top: 0px;
  }
  & .waring-msg {
    color: ${COLORS.WARNING_COLOR};
    font-size: 10px;
    white-space: nowrap;
  }
  & .mediator-type {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: column !important;
      & .MuiFormControlLabel-root {
        padding: 10px 0px;
      }
    }
  }
  @media ${theme?.breakpoints?.sm_down} {
    & .template {
      margin-top: 15px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    width: auto;
  }
`;
