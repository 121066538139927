import React, { useState, useRef } from "react";
import ActionBar from "../../common/ActionBar";
import labels from "../../../helpers/labels.json";
import _ from "lodash";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import styled from "styled-components";
import CustomTable from "../../common/CustomTable/CustomTable";
import CaseService from "../../../services/CaseService";
import queryString from "query-string";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import { navigate } from "@reach/router";

const Index = () => {
  const MTRef = useRef();
  const [result, setResult] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const placeholderText = "Search CaseID";

  const getCasesDetails = async (query) => {
    try {
      const response = await CaseService.getCasesDetails(query);
      return { ...response };
    } catch (error) {
      throw error;
    }
  };

  const columns = [
    {
      field: "caseId",
      title: "Case ID",
      sorting: false,
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(
              `/dashboard/case-manager/${rowData?.resolutionKind}/${rowData?.caseId}`,
            )
          }
        >
          {rowData?.caseId}
        </HyperLink>
      ),
      headerStyle: {
        paddingLeft: "26px",
      },
    },
    {
      field: "meetingId",
      title: "Case Meetings",
      sorting: false,
      render: (rowData) => (
        <HyperLink
          style={{ marginLeft: "15px" }}
          onClick={() =>
            navigate(
              `/dashboard/case-manager/meeting-details/session-management`,
              {
                state: rowData,
              },
            )
          }
        >
          {rowData?.meetingId}
        </HyperLink>
      ),
    },
    {
      field: "resolutionKind",
      title: "Resolution Kind",
      sorting: false,
      render: (rowData) => <Bold>{_.capitalize(rowData?.resolutionKind)}</Bold>,
    },
    {
      field: "meetingStatus",
      title: "Status",
      sorting: false,
      render: (rowData) => (
        <Bold style={{ marginLeft: "0px" }}>{rowData?.meetingStatus}</Bold>
      ),
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (query.search) {
        params.search = query.search;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getCasesDetails(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setResult(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setResult({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <React.Fragment>
      <ActionBar breadcrumbs={[_.startCase(labels.meeting_details)]} />
      <Container>
        <TableWrapper>
          <CustomTable
            pluralTitle="Meeting Details"
            singularTitle=""
            {...{
              columns,
              data,
              MTRef,
              placeholderText,
            }}
            // noToolbar
            hideFilterBar
            pageSize={10}
            hidePagination={result.lastPage === 1}
            state={result}
          />
        </TableWrapper>
      </Container>
    </React.Fragment>
  );
};

export default Index;

const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  font-size: 14px;
`;

const Bold = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-left: 15px;
`;

export const Container = styled.div`
  padding: 34px 43px;
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div``;
