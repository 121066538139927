import React, { useState, useEffect } from "react";
import {
  Container,
  CreateContainer,
  StyledForm,
  StyledFormElementsWrapper,
  FormLabel,
  CheckboxGrid,
  Button,
} from "./styles";
import ActionBar from "../../common/ActionBar";
import {
  CustomCheckbox,
  CheckBoxLabel,
  CheckboxContainer,
} from "../../common/FormInputs";
import { Formik } from "formik";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage, getFileName } from "../../../helpers/functions";
import AWSService from "../../../services/AWSService";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton,
} from "../../common/Buttons";
import UploadNotice from "./component/UploadNotice";
import GenerateNotice from "./component/GenerateNotice";
import PartyService from "../../../services/PartyService";
import { NoticeGenerationSchema } from "../../../helpers/validationSchema";
import NoticeService from "../../../services/NoticeService";
import HeaderFooter from "./component/HeaderFooter";
import { navigate } from "@reach/router";
import UpdateNotice from "./component/UpdateNotice";
import NoticeDesign from "./component/NoticeDesign";

const NoticeGeneration = () => {
  const [options, setOptions] = useState(1);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [menuItems, setMenuItems] = useState([]);
  const [menuNotice, setMenuNotice] = useState([]);
  const [menuTemplateCode, setMenuTemplateCode] = useState([]);
  const [menuHeaderFooter, setMenuHeaderFooter] = useState([]);
  const [letterHeadArr, setLetterHeaderArr] = useState([]);
  const [find, setFind] = useState(false);
  const [letterMenuItems, setLetterMenuItems] = useState([]);
  const [triggerNotify, setTriggerNotify] = useState(true);

  useEffect(() => {
    async function getParties() {
      try {
        setLoader({ state: true });
        const response = await PartyService.index(
          "?perPage=4000&status=active",
        );
        if (response?.data?.length) {
          setMenuItems(
            response.data.map(({ name, id, owner }) => ({
              label: name,
              value: id,
              email: owner?.email,
            })),
          );
        } else {
          enqueueSnackbar(
            "You need to create a Party inorder to create a case",
            {
              variant: "error",
              autoHideDuration: 3000,
            },
          );
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    getParties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getNoticeTemplates(partyId) {
    try {
      setLoader({ state: true, message: "fetching notice details..." });
      const response = await NoticeService.getNoticeTemplates(partyId);
      if (response[0]) {
        setMenuNotice(
          response[0]?.noticeCategory?.map((item) => ({
            label: item,
            value: item,
          })),
        );
        setMenuTemplateCode(response[0]?.codeAndTemplates);
        const arrayOfObject = response[0]?.letterHeadArr.map((item) => ({
          label: item,
          value: item,
        }));
        setMenuHeaderFooter([{ label: "None", value: "" }, ...arrayOfObject]);
        setLetterHeaderArr(response[0]?.letterHeadOption);
      }
    } catch (err) {
      enqueueSnackbar(err?.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const checkbox_actions = [
    { label: "Create", name: "create", value: 1 },
    { label: "Update", name: "update", value: 4 },
    { label: "Generate", name: "generate", value: 2 },
    {
      label: "Add Letter Head",
      name: "headerFooterOptions",
      value: 3,
    },
    {
      label: "Notice design",
      name: "noticeDesign",
      value: 5,
    },
  ];

  const inputs = [
    {
      type: "text",
      name: "noticeCategory",
      label: "Notice Category",
      required: true,
    },
    {
      type: "text",
      name: "templateCode",
      label: "Template Code",
      required: true,
    },
  ];

  const upload_templates = [
    {
      name: "noticeTemplate",
      label: "Upload Notice Template (docx)",
      type: "upload",
      required: true,
      accept: ".docx",
      types: [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
    },
    {
      name: "excelTemplate",
      label: "Upload Excel Template (xlsx)",
      type: "upload",
      required: true,
      accept: ".xlsx",
      types: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "text/csv",
      ],
    },
  ];

  async function s3Upload(file, field, setFieldValue, fileTypes) {
    if (file) {
      try {
        const types = fileTypes;
        setLoader({ state: true, message: `Uploading file ${file.name}` });
        const errorMessage = "Invalid File Type";
        if (!types.includes(file?.type)) throw errorMessage;
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  async function onFormSubmit(values, { resetForm }) {
    try {
      setLoader({ state: true, message: "please wait..." });
      if (values?.noticeOptions === 1) {
        const payload = {
          partyId: values?.partyId,
          noticeCategory: values?.noticeCategory,
          templateCode: values?.templateCode,
          excelTemplate: values?.excelTemplate,
          noticeTemplate: values?.noticeTemplate,
        };
        const response = await NoticeService.uploadNoticeTemplates(payload);
        if (response) {
          enqueueSnackbar(response?.message, {
            variant: "success",
          });
          resetForm();
        }
        setOptions(values?.noticeOptions);
      } else if (values?.noticeOptions === 2) {
        const payload = {
          partyId: values?.partyId,
          noticeCategory: values?.noticeCategory,
          templateCode: values?.templateCode,
          excelFile: values?.excelFile,
          lotNo: values?.lotNo,
          noticeTemplate: menuTemplateCode[values?.noticeCategory].filter(
            (template) => template?.templateCode === values?.templateCode,
          )[0]?.noticeTemplate,
          letterHeadName: values?.letterHeadName,
          letterHeadImages: values?.letterHeadName
            ? letterHeadArr[values?.letterHeadName]?.length &&
              letterHeadArr[values?.letterHeadName][0]
            : { headerImage: "", footerImage: "" },
        };
        const response = await NoticeService.bulkNoticeGenerate(payload);
        if (response?.message) {
          enqueueSnackbar(response?.message, {
            variant: "success",
          });
          resetForm();
        }
        setOptions(values?.noticeOptions);
      } else if (values?.noticeOptions === 3) {
        const payload = {
          letterHeadName: values?.letterHeadName,
          headerImage: values?.headerImage,
          footerImage: values?.footerImage,
        };
        if (find) {
          const response = await NoticeService.addLetterHeadOption(payload);
          if (response?.message) {
            enqueueSnackbar(response?.message, {
              variant: "success",
            });
            setTriggerNotify(true);
            resetForm();
          }
          setOptions(values?.noticeOptions);
        } else {
          let id = await letterMenuItems.filter(
            (item) => values?.letterHeadName === item.label,
          )[0]?.id;
          const response = await NoticeService.updateLetterHeadOption(
            payload,
            id,
          );
          if (response?.message) {
            enqueueSnackbar(response?.message, {
              variant: "success",
            });
            resetForm();
          }
          setOptions(values?.noticeOptions);
        }
      } else if (values?.noticeOptions === 4) {
        const payload = {
          noticeCategory: values?.noticeCategory,
          templateCode: values?.templateCode,
          excelTemplate: values?.excelTemplate,
          noticeTemplate: values?.noticeTemplate,
        };
        let id = await menuTemplateCode[values?.noticeCategory].filter(
          (templateCode) => templateCode?.templateCode === values?.templateCode,
        )[0]?.id;
        const response = await NoticeService.updateNoticeTemplates(payload, id);
        if (response) {
          enqueueSnackbar(response?.message, {
            variant: "success",
          });
          resetForm();
        }
        setOptions(values?.noticeOptions);
      } else {
        const payload = {
          lineSpacing: values?.lineSpacing,
          afterLineSpacing: values?.afterLineSpacing,
          topMargin: values?.topMargin,
          bottomMargin: values?.bottomMargin,
          leftMargin: values?.leftMargin,
          rightMargin: values?.rightMargin,
          headerDistance: values?.headerDistance,
          footerDistance: values?.footerDistance,
          fontSize: values?.fontSize,
        };
        // let id = await menuTemplateCode[values?.noticeCategory].filter(
        //   (templateCode) => templateCode?.templateCode === values?.templateCode,
        // )[0]?.id;
        // const response = await NoticeService.updateNoticeTemplates(payload, id);
        // if (response) {
        //   enqueueSnackbar(response?.message, {
        //     variant: "success",
        //   });
        //   resetForm();
        // }
        // setOptions(values?.noticeOptions);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setFind(false);
    }
  }

  return (
    <>
      <ActionBar breadcrumbs={["Notice Generation"]} />
      <Container>
        <CreateContainer className="CreateCase"></CreateContainer>
        <Formik
          initialValues={{
            noticeOptions: options || "",
            noticeCategory: "",
            templateCode: "",
            letterHeadName: "",
            lotNo: "",
            noticeTemplate: "",
            excelTemplate: "",
            partyId: "",
            excelFile: "",
            headerImage: "",
            footerImage: "",
            lineSpacing: 10,
            afterLineSpacing: 1,
            topMargin: 2.25,
            bottomMargin: 1.25,
            leftMargin: 1.5,
            rightMargin: 1.5,
            headerDistance: 0.25,
            footerDistance: 0.25,
            fontSize: 10,
          }}
          validationSchema={NoticeGenerationSchema}
          enableReinitialize
          onSubmit={onFormSubmit}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldError,
            resetForm,
          }) => (
            <StyledForm>
              <StyledFormElementsWrapper
                style={{ marginBottom: 15, marginTop: 15, maxWidth: "100%" }}
              >
                <FormLabel>Bulk Notice</FormLabel>
                <CheckboxGrid>
                  {checkbox_actions.map((item, index) => (
                    <CheckboxContainer>
                      <CustomCheckbox
                        name="noticeOptions"
                        value={values.noticeOptions}
                        checked={item?.value === options}
                        onChange={() => {
                          setFieldValue("noticeOptions", item.value);
                          setOptions(item.value);
                          setFind(false);
                          resetForm();
                        }}
                      />
                      <CheckBoxLabel>{item.label}</CheckBoxLabel>
                    </CheckboxContainer>
                  ))}
                </CheckboxGrid>
              </StyledFormElementsWrapper>
              {values?.noticeOptions === 1 ? (
                <UploadNotice
                  {...{
                    values,
                    handleChange,
                    errors,
                    touched,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    inputs,
                    upload_templates,
                    s3Upload,
                    getFileName,
                    menuItems,
                  }}
                />
              ) : values?.noticeOptions === 2 ? (
                <GenerateNotice
                  {...{
                    values,
                    handleChange,
                    errors,
                    touched,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    inputs,
                    upload_templates,
                    s3Upload,
                    getFileName,
                    menuItems,
                    menuNotice,
                    menuTemplateCode,
                    menuHeaderFooter,
                    getNoticeTemplates,
                  }}
                />
              ) : values?.noticeOptions === 3 ? (
                <HeaderFooter
                  {...{
                    values,
                    handleChange,
                    errors,
                    touched,
                    handleBlur,
                    setFieldValue,
                    setFind,
                    letterMenuItems,
                    setLetterMenuItems,
                    triggerNotify,
                    setTriggerNotify,
                  }}
                />
              ) : values?.noticeOptions === 4 ? (
                <UpdateNotice
                  {...{
                    values,
                    handleChange,
                    errors,
                    touched,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    inputs,
                    upload_templates,
                    s3Upload,
                    getFileName,
                    menuItems,
                    menuNotice,
                    menuTemplateCode,
                    getNoticeTemplates,
                  }}
                />
              ) : (
                <NoticeDesign
                  {...{
                    values,
                    handleChange,
                    errors,
                    touched,
                    handleBlur,
                    setFieldValue,
                    inputs,
                    upload_templates,
                    s3Upload,
                    getFileName,
                    menuItems,
                  }}
                />
              )}
              <StyledFormElementsWrapper
                style={{
                  float: "left",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "25px",
                  width: "35%",
                }}
              >
                <Button
                  style={{
                    marginLeft: "unset",
                  }}
                >
                  <PrimaryOutlinedCTAButton
                    onClick={() => navigate(`/dashboard`)}
                  >
                    Back
                  </PrimaryOutlinedCTAButton>
                </Button>
                <Button
                  style={{
                    marginLeft: "22px",
                  }}
                >
                  <PrimaryCTAButton onClick={handleSubmit}>
                    {values?.noticeOptions === 1
                      ? "Upload"
                      : values?.noticeOptions === 2
                      ? "Generate Notice"
                      : values?.noticeOptions === 5
                      ? "Create Design"
                      : values?.noticeOptions === 3
                      ? find
                        ? "Add"
                        : "Update"
                      : "Update"}
                  </PrimaryCTAButton>
                </Button>
              </StyledFormElementsWrapper>
            </StyledForm>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default NoticeGeneration;
