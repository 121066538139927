import React from "react";
import { Header } from "../../internal/Header/Header";
import labels from "../../../helpers/labels.json";
import { useLocation } from "@reach/router";
import { getPathAfterCaseManager } from "../../../helpers/functions";

export default function MainRoute({
  showSidebar = true,
  // selectedItem,
  // selectedSubItem,
  children,
}) {
  const location = useLocation();

  const selectedSubItem =
    getPathAfterCaseManager(location?.pathname) === "proforma-invoice"
      ? labels.proforma_invoice
      : getPathAfterCaseManager(location?.pathname) === "uploads"
      ? labels.upload
      : getPathAfterCaseManager(location?.pathname) === "verify-parties"
      ? labels.verify
      : getPathAfterCaseManager(location?.pathname) === "bulk-notice"
      ? labels.bulk_notice
      : getPathAfterCaseManager(location?.pathname) === "document-reports"
      ? labels.document_reports
      : getPathAfterCaseManager(location?.pathname) === "negotiation"
      ? labels.negotiation
      : getPathAfterCaseManager(location?.pathname) === "mediation"
      ? labels.mediation
      : getPathAfterCaseManager(location?.pathname) === "arbitration"
      ? labels.arbitration
      : getPathAfterCaseManager(location.pathname) === "bulk-uploads"
      ? labels.bulk_uploads
      : getPathAfterCaseManager(location.pathname) === "parties"
      ? labels.parties
      : getPathAfterCaseManager(location.pathname) === "profiles"
      ? labels.profiles
      : getPathAfterCaseManager(location.pathname) === "payments"
      ? labels.payments
      : getPathAfterCaseManager(location.pathname) === "generic-meeting"
      ? labels.generic_meeting
      : getPathAfterCaseManager(location.pathname) === "meeting-details"
      ? labels.meeting_details
      : getPathAfterCaseManager(location.pathname) === "daily-cause"
      ? labels.daily_casuse
      : location.pathname.includes("dashboard/case-manager")
      ? labels.pending_actions
      : "";

  const selectedItem =
    getPathAfterCaseManager(location?.pathname) === "proforma-invoice" ||
    getPathAfterCaseManager(location?.pathname) === "uploads" ||
    getPathAfterCaseManager(location?.pathname) === "verify-parties" ||
    getPathAfterCaseManager(location?.pathname) === "bulk-notice" ||
    getPathAfterCaseManager(location?.pathname) === "document-reports"
      ? labels.my_tasks
      : getPathAfterCaseManager(location.pathname) === "generic-meeting" ||
        getPathAfterCaseManager(location.pathname) === "meeting-details" ||
        getPathAfterCaseManager(location.pathname) === "daily-cause"
      ? labels.meetings
      : getPathAfterCaseManager(location?.pathname) === "negotiation" ||
        getPathAfterCaseManager(location?.pathname) === "mediation" ||
        getPathAfterCaseManager(location?.pathname) === "arbitration"
      ? labels.cases
      : getPathAfterCaseManager(location.pathname) === "parties"
      ? labels.parties
      : getPathAfterCaseManager(location.pathname) === "profiles"
      ? labels.profiles
      : getPathAfterCaseManager(location.pathname) === "payments"
      ? labels.payments
      : getPathAfterCaseManager(location.pathname) === "transcriber"
      ? labels.transcriber
      : getPathAfterCaseManager(location.pathname) === "mis-dashboard"
      ? labels.mis_dashboard
      : getPathAfterCaseManager(location.pathname) === "tools"
      ? labels.management_tools
      : location.pathname.includes("dashboard/case-manager")
      ? labels.my_tasks
      : "";

  console.log(
    getPathAfterCaseManager(location?.pathname) === "negotiation" ||
      getPathAfterCaseManager(location?.pathname) === "mediation" ||
      getPathAfterCaseManager(location?.pathname) === "arbitration",
    "pathname",
  );
  return (
    <Header {...{ showSidebar, selectedItem, selectedSubItem }}>
      {children}
    </Header>
  );
}
