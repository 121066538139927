import React, { Fragment, useEffect, useState } from "react";
import ActionBar from "../../common/ActionBar";
import labels from "../../../helpers/labels.json";
import _ from "lodash";
import { Form, Formik } from "formik";
import { navigate } from "@reach/router";
import { CreateNeutrals } from "../../../helpers/validationSchema";
import FormField from "../../common/FormField/FormField";
import { PrimaryCTAButton } from "../../common/Buttons";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import useLoader from "../../../hooks/useLoader";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";

const CreateNeutral = ({ id }) => {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState();
  const breadcrumbs = [
    _.startCase(labels.profiles),
    _.startCase(id ? labels.edit_neutral : labels.create_neutral),
  ];
  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "profiles":
        navigate("/dashboard/case-manager/profiles");
        break;
      default:
        break;
    }
  };

  const inputs = [
    {
      name: "name",
      type: "text",
      label: "Name of the user",
      required: true,
    },
    {
      name: "email",
      type: "email",
      label: "Email of the user",
      required: true,
    },
    {
      name: "mobile",
      type: "text",
      label: "Phone no of the user",
      required: true,
    },
    {
      name: "password",
      type: "password",
      label: "Password",
      required: !id,
    },
  ];

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "Loading..." });
      let payload = {
        ...values,
      };
      if (id) {
        const response = await CaseService.updatePassiveNeutral(payload, id);
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          navigate("/dashboard/case-manager/profiles");
        }
      } else {
        const response = await CaseService.createPassiveNeutral(payload);
        if (response) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          navigate("/dashboard/case-manager/profiles");
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  useEffect(() => {
    async function getUsersDetails(id) {
      try {
        setLoader({ state: true, message: "Fetching neutral details..." });
        const response = await CaseService.getIndividualUser(id);
        if (response) {
          setState(response);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }

    if (id) {
      getUsersDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Fragment>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <div>
        <Formik
          initialValues={{
            name: state?.name || "",
            email: state?.email || "",
            mobile: state?.mobile || "",
            password: state?.password || "",
            type: id ? "EDIT" : "CREATE",
          }}
          validationSchema={CreateNeutrals}
          enableReinitialize
          onSubmit={onFormSubmit}
          validateOnBlur
          validateOnChange
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            handleBlur,
            isValid,
            setFieldValue,
            setFieldError,
          }) => (
            <StyledForm>
              <Form>
                <Container>
                  <div className="mb30">
                    <Heading>
                      <BackArrow
                        onClick={() =>
                          navigate(`/dashboard/case-manager/profiles`)
                        }
                        src={require("../../../assets/images/backArrow.svg")}
                      />
                      {_.startCase(
                        id ? labels.edit_neutral : labels.create_neutral,
                      )}
                    </Heading>
                  </div>
                  {inputs?.map((input, key) => (
                    <FormField
                      white
                      {...{
                        key,
                        input,
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                      }}
                    />
                  ))}
                  <PrimaryCTAButton className="mt20" onClick={handleSubmit}>
                    {id ? "Update View Only User" : "Create View Only User"}
                  </PrimaryCTAButton>
                </Container>
              </Form>
            </StyledForm>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};

export default CreateNeutral;

const BackArrow = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14.5px;
  cursor: pointer;
`;

const Heading = styled.span`
  display: flex;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.COLOR_DARK};
`;

const Container = styled.div`
  padding: 34px 43px;
  display: flex;
  flex-direction: column;
`;

const StyledForm = styled.form`
  width: 436px;
`;
