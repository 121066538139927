import React from "react";
import { StyledFormElementsWrapper, ContainerGrid } from "../styles";
import CustomSelect from "./CustomSelect";
import FormField from "../../../common/FormField/FormField";
import styled from "styled-components";

const NoticeDesign = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  upload_templates,
  s3Upload,
  getFileName,
  menuItems,
}) => {
  const inputs = [
    {
      type: "text",
      name: "lineSpacing",
      label: "Line Spacing",
      placeholder: "10",
    },
    {
      type: "text",
      name: "afterLineSpacing",
      label: "After Line Spacing",
      placeholder: "1",
    },
    {
      type: "text",
      name: "topMargin",
      label: "Top Margin",
      placeholder: "2.25",
    },
    {
      type: "text",
      name: "bottomMargin",
      label: "Bottom Margin",
      placeholder: "1.25",
    },
    {
      type: "text",
      name: "leftMargin",
      label: "Left Margin",
      placeholder: "1.5",
    },
    {
      type: "text",
      name: "rightMargin",
      label: "Right Margin",
      placeholder: "1.5",
    },
    {
      type: "text",
      name: "headerDistance",
      label: "Header Distance",
      placeholder: "0.25",
    },
    {
      type: "text",
      name: "footerDistance",
      label: "Footer Distance",
      placeholder: "0.25",
    },
    {
      type: "text",
      name: "fontSize",
      label: "Font Size",
      placeholder: "10",
    },
  ];

  return (
    <StyledFormElementsWrapper
      style={{ marginBottom: 8, marginTop: 15 }}
      width={"100%"}
    >
      <ContainerGrid>
        <CustomSelect
          name="partyId"
          trigger={false}
          {...{
            errors,
            touched,
            setFieldValue,
            menuItems,
          }}
        />
      </ContainerGrid>
      <Grid className="input-text">
        {inputs.map((input, index) => {
          return (
            <FormField
              key={index}
              {...{
                touched,
                errors,
                input,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
              }}
            />
          );
        })}
      </Grid>
    </StyledFormElementsWrapper>
  );
};

export default NoticeDesign;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 10px;
`;
