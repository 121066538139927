import React, { useState, useEffect, useRef, useMemo } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import {
  Modal,
  RadioGroup,
  Radio,
  FormControlLabel,
  makeStyles,
  CircularProgress,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Formik } from "formik";
import FormField from "../../common/FormField/FormField";
import useSettings from "../../../hooks/useSettings";
import SearchBar from "../../common/SearchBar";
import {
  CheckboxContainer,
  CheckBoxLabel,
  CustomCheckbox,
  CustomCheckBox,
} from "../../common/FormInputs";
import useDebounce from "../../../hooks/useDebounce";
import SystemService from "../../../services/SystemService";
import _ from "lodash";
import queryString from "query-string";
import {
  EditorContainer,
  ButtonContainer,
  Label,
} from "../../pages/CaseDetails/common/CommentBox";
import AWSService from "../../../services/AWSService";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import ReactQuill from "react-quill";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import OrderDictation from "../../internal/OrderDictation/LiveDictation";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import AddZoomLinkModal from "../../common/AddZoomLinkModal";
import useUser from "../../../hooks/useUser";
import { bulkUpdateValidationSchema } from "../../../helpers/validationSchema";

const ESCAPE_HTML_REGEX = /<[^>]+>/g;

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: COLORS.PRIMARY_WHITE,
    boxShadow: "none",
    width: "77px",
    height: "30px",
    color: COLORS.BTN_GREEN,
    textTransform: "Capitalize",
    border: "solid 1px #00838c",
    fontFamily: theme.fonts.primaryFontBold,
    fontSize: 14,
  },
  formLabel: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.COLOR_DARK,
  },
  table: {
    backgroundColor: "transparent",
    border: "1px solid #e1e3ee",
    borderRadius: 7,
    "& .MuiTableCell-root": {
      borderBottom: 0,
    },
  },
  menuRoot: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    fontSize: 12,
    color: COLORS.COLOR_DARK,
  },
  tableHead: {
    fontFamily: "openSans-SemiBold, sans-serif",
    color: COLORS.INPUT_LABEL,
    fontSize: 12,
  },
  tableBody: {
    fontFamily: "openSans-SemiBold, sans-serif",
    color: COLORS.COLOR_DARK,
    fontSize: 12,
  },
}));

const BulkUpdate = ({ bulkUpdate, setBulkUpdate }) => {
  const classes = useStyles();
  const [inputText, setInputText] = useState("");
  const { caseLifeCycleStatus } = useSettings();
  const debounceValue = useDebounce(inputText, 500);
  const [neutralList, setNeutralList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const quillRef = useRef();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modal, setModal] = useState({ state: false });
  const { userObject } = useUser();

  useEffect(() => {
    if (bulkUpdate) {
      getNeutralList(debounceValue);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkUpdate, debounceValue]);

  const inputs = [
    {
      label: "From CaseId",
      name: "fromCaseId",
      type: "number",
      required: true,
    },
    {
      label: "To CaseId",
      name: "toCaseId",
      type: "number",
      required: true,
    },
    {
      type: "text",
      name: "expectCaseIds",
      label: "Except CaseIds",
      required: false,
    },
  ];

  const radio_items = [
    {
      label: "Record the procedings",
      name: "recordingProceeding",
      value: "recordingProceeding",
    },
    {
      label: "Case Status",
      name: "caseStatus",
      value: "caseStatus",
      disabled: true,
    },
    {
      label: "Assign Neutral",
      name: "assignNeutral",
      value: "assignNeutral",
      disabled: true,
    },
  ];

  const server_items = [
    {
      label: "Transcription Server (1)",
      name: 1,
      value: 1,
    },
    {
      label: "Transcription Server (2)",
      name: 2,
      value: 2,
    },
  ];

  async function getNeutralList(query) {
    try {
      setLoading(true);
      let params = {
        page: 1,
        perPage: 1000,
        role: "mediator",
      };
      if (query) {
        params.query = query;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      const response = await SystemService.getProfiles(stringParams);
      if (response) {
        setNeutralList(response?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  const imageHandler = (e) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*, application/pdf");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("image", file);
      const response = await uploadFileToServer(file); // upload data into server or aws or cloudinary
      const quill = quillRef.current.getEditor(); // Access the Quill instance
      const range = quill.getSelection(); // Get the current cursor position
      quill.insertText(range.index, file?.name, "link", response);
      quill.formatText(range.index, file?.name.length, { color: "#00838c" });
    };
  };

  const uploadFileToServer = async (file) => {
    // Example upload logic (replace with your implementation)
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoader({ state: true, message: `uploading ${file.name}` });
      const payload = {
        key: file.name,
      };
      const s3Response = await AWSService.getS3URL(payload);
      if (s3Response.url) {
        const response = await AWSService.uploadToS3(s3Response.url, file);
        return response; // Assuming the server returns the uploaded file URL
      }
    } catch (error) {
      enqueueSnackbar(`File upload failed`, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            "bold",
            "italic",
            "underline",
            "blockquote",
            "image",
          ],
          // ["image"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }), // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sendTypeOfData = (values) => {
    if (values?.typeOfLoad === "recordingProceeding") {
      let postbody = {
        content: values?.isFromZoom ? "" : comment,
        visibilityType: values?.visibilityType,
      };
      if (values?.isFromZoom) {
        postbody = { ...postbody, ...values?.isFromZoom };
      }

      return postbody;
    } else if (values?.typeOfLoad === "caseStatus") {
      let postbody = {
        status: values.caseStatus,
        currentUser: userObject?.name,
      };
      return postbody;
    } else {
      let postbody = {
        individualMail: values?.individualMail,
        requestedMediators: values?.requestedMediators,
      };

      return postbody;
    }
  };

  async function onFormSubmit(values, { resetForm }) {
    try {
      setLoader({ state: true, message: "Submitting response..." });
      let payload = {
        fromCaseId: values?.fromCaseId,
        toCaseId: values?.toCaseId,
        expectCaseIds: values?.expectCaseIds,
        updates: [
          {
            type: values?.typeOfLoad,
            data: sendTypeOfData(values),
          },
        ],
      };
      const response = await CaseService.bulkUpdates(payload);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      resetForm();
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setAnchorEl(null);
      setModal({ state: false });
      setComment("");
      setBulkUpdate(false);
    }
  }

  return (
    <>
      <Modal
        open={bulkUpdate}
        onClose={() => setBulkUpdate(false)}
        disableRestoreFocus
      >
        <DrawerContainer>
          <Container>
            <HeadingContainer>
              <Heading>{"Bulk update"}</Heading>
              <CloseModal
                onClick={() => setBulkUpdate(false)}
                src={require("../../../assets/images/closeModal.svg")}
              />
            </HeadingContainer>
            <Formik
              initialValues={{
                fromCaseId: "",
                toCaseId: "",
                expectCaseIds: "",
                comment: "",
                visibilityType: "all",
                isFromZoom: "",
                isLocalTranscriptionServer: 1,
                caseStatus: "",
                individualMail: false,
                typeOfLoad: "recordingProceeding",
                requestedMediators: [],
              }}
              enableReinitialize
              validationSchema={bulkUpdateValidationSchema}
              validateOnBlur
              validateOnChange
              onSubmit={onFormSubmit}
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                touched,
                setFieldValue,
                setFieldError,
                handleBlur,
                isValid,
                resetForm,
              }) => (
                <FormContainer>
                  <StyledForm
                    style={{
                      padding: "20px 2px 20px 0px",
                      marginLeft: "10px",
                    }}
                  >
                    <div className="input-text">
                      <Grid>
                        {inputs?.map((input, key) => (
                          <FormField
                            white
                            {...{
                              key,
                              input,
                              values,
                              errors,
                              touched,
                              handleBlur,
                              handleChange,
                              setFieldValue,
                            }}
                          />
                        ))}
                      </Grid>
                    </div>

                    <Grid style={{ marginTop: "16px", padding: 16 }}>
                      <RadioGroup
                        className={classes.radioGroup}
                        style={{
                          flexDirection: "row",
                          marginLeft: "10px",
                        }}
                        name={"typeOfLoad"}
                        value={values.typeOfLoad}
                        onChange={handleChange}
                      >
                        {radio_items.map((radio, key) => (
                          <FormControlLabel
                            {...{ key }}
                            classes={{ label: classes.formLabel }}
                            value={radio.value}
                            disabled={radio.disabled}
                            control={
                              <Radio
                                size="medium"
                                style={{ color: "#00838c" }}
                              />
                            }
                            label={radio.label}
                          />
                        ))}
                      </RadioGroup>
                    </Grid>

                    <>
                      {values?.typeOfLoad === "caseStatus" && (
                        <div className="input-search">
                          <SearchBar
                            placeHolderText={"Search Sub Stages"}
                            backgroundColor="#f9f9f9"
                            {...{ inputText, setInputText }}
                            width="100%"
                          />
                        </div>
                      )}
                      {values.typeOfLoad === "assignNeutral" && (
                        <div className="input-search">
                          <SearchBar
                            placeHolderText={"Search Neutral"}
                            backgroundColor="#f9f9f9"
                            {...{ inputText, setInputText }}
                            width="100%"
                          />
                        </div>
                      )}
                      {values.typeOfLoad === "caseStatus" ? (
                        <div className="status-list">
                          {caseLifeCycleStatus?.length ? (
                            caseLifeCycleStatus
                              .filter((stage) =>
                                stage.label
                                  .toLowerCase()
                                  .includes(inputText.toLowerCase()),
                              )
                              .map((action) => (
                                <CheckboxContainer className="status_row">
                                  <CustomCheckBox
                                    name={"caseStatus"}
                                    checked={values.caseStatus === action.value}
                                    onChange={() => {
                                      const isAlreadyAdded =
                                        values.caseStatus === action.value;
                                      setFieldValue(
                                        "caseStatus",
                                        isAlreadyAdded ? "" : action.value,
                                      );
                                    }}
                                  />
                                  <CheckBoxLabel className="status_name">
                                    {action?.label}
                                  </CheckBoxLabel>
                                </CheckboxContainer>
                              ))
                          ) : (
                            <div className="loader">
                              <CircularProgress />
                            </div>
                          )}
                        </div>
                      ) : values.typeOfLoad === "assignNeutral" ? (
                        <>
                          <div className="status-list">
                            {loading ? (
                              <div className="loader">
                                <CircularProgress />
                              </div>
                            ) : (
                              <>
                                {neutralList?.length > 0 ? (
                                  <>
                                    {neutralList
                                      ?.filter((arr) =>
                                        arr.name
                                          .toLowerCase()
                                          .includes(inputText.toLowerCase()),
                                      )
                                      .map((neutral) => (
                                        <CheckboxContainer className="status_row">
                                          <CustomCheckBox
                                            name={"caseStatus"}
                                            checked={values.requestedMediators.some(
                                              (m) => m.id === neutral?.id,
                                            )}
                                            onChange={() => {
                                              const isAlreadyAdded =
                                                values.requestedMediators.some(
                                                  (m) => m.id === neutral?.id,
                                                );
                                              setFieldValue(
                                                "requestedMediators",
                                                isAlreadyAdded
                                                  ? []
                                                  : [
                                                      {
                                                        email: neutral?.email,
                                                        name: neutral?.name,
                                                        mobile: neutral?.mobile,
                                                        id: neutral?.id,
                                                      },
                                                    ],
                                              );
                                            }}
                                          />
                                          <div
                                            className={`neutral_row ${
                                              neutral?.disabled
                                                ? "disabled"
                                                : ""
                                            }`}
                                          >
                                            <div className="neutral_name">
                                              <div>{neutral?.name}</div>
                                              <div>({neutral?.email})</div>
                                            </div>
                                          </div>
                                        </CheckboxContainer>
                                      ))}
                                  </>
                                ) : (
                                  <div className="no_result">
                                    No Neutrals found.
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <CheckboxContainer
                            style={{ marginTop: "10px" }}
                            className="cm_row"
                          >
                            <CustomCheckbox
                              name="individualMail"
                              checked={values.individualMail}
                              onChange={handleChange}
                            />
                            <CheckBoxLabel>
                              Select if mail should go individually
                            </CheckBoxLabel>
                          </CheckboxContainer>
                        </>
                      ) : (
                        <>
                          <Grid
                            style={{
                              marginTop: "5px",
                              paddingLeft: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            <RadioGroup
                              className={classes.radioGroup}
                              style={{
                                flexDirection: "row",
                                marginLeft: "10px",
                              }}
                              name={"isLocalTranscriptionServer"}
                              value={values.isLocalTranscriptionServer}
                              onChange={(e) =>
                                setFieldValue(
                                  "isLocalTranscriptionServer",
                                  parseInt(e.target.value),
                                )
                              }
                            >
                              {server_items.map((radio, key) => (
                                <FormControlLabel
                                  {...{ key }}
                                  classes={{ label: classes.formLabel }}
                                  value={radio.value}
                                  control={
                                    <Radio
                                      size="medium"
                                      style={{ color: "#7E99A3" }}
                                    />
                                  }
                                  label={radio.label}
                                />
                              ))}
                            </RadioGroup>
                          </Grid>
                          <div style={{ display: "flex" }}>
                            <EditorContainer>
                              <ReactQuill
                                ref={quillRef}
                                value={comment}
                                onChange={(text) => setComment(text)}
                                modules={modules}
                                placeholder="Record the Proceedings…"
                                // readOnly={disabled}
                              />
                            </EditorContainer>
                            <ButtonContainer>
                              <div className="vcLink">
                                <PrimaryCTAButton
                                  style={{ padding: "12px 22px" }}
                                  onClick={() => setModal({ state: true })}
                                >
                                  Add VC Link
                                </PrimaryCTAButton>
                              </div>
                              <PrimaryCTAButton
                                className="vcLink"
                                style={{ padding: "12px 22px" }}
                                aria-controls="comment-send-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                disabled={
                                  !comment
                                    ?.replace(ESCAPE_HTML_REGEX, "")
                                    .trim()
                                }
                              >
                                Send
                              </PrimaryCTAButton>
                              <div className="dictation">
                                <OrderDictation
                                  {...{ setComment }}
                                  isLocalTranscriptionServer={
                                    values.isLocalTranscriptionServer
                                  }
                                  width={"30%"}
                                />
                              </div>
                              <Menu
                                id="comment-send-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                classes={{ paper: classes.paper }}
                              >
                                <Label>SEND TO</Label>
                                {[
                                  { label: "All", value: "all" },
                                  { label: "Claimant", value: "claimant" },
                                  { label: "Respondent", value: "respondent" },
                                  { label: "Neutral", value: "mediator" },
                                ].map((item) => (
                                  <MenuItem
                                    className={classes.menuRoot}
                                    onClick={() => {
                                      setFieldValue(
                                        "visibilityType",
                                        item.value,
                                      );
                                      setAnchorEl(null);
                                    }}
                                  >
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </Menu>
                            </ButtonContainer>
                          </div>
                          <AddZoomLinkModal
                            {...{ modal, setModal }}
                            onSubmit={(data) => {
                              setFieldValue("isFromZoom", data);
                              setModal({ state: false });
                            }}
                          />
                        </>
                      )}
                    </>
                  </StyledForm>
                  <ButtonWrapper>
                    <PrimaryOutlinedCTAButton
                      style={{
                        marginRight: "10px",
                        width: "auto",
                        padding: "10px 10px",
                      }}
                      onClick={() => {
                        resetForm();
                        setComment("");
                      }}
                    >
                      Clear form
                    </PrimaryOutlinedCTAButton>
                    <PrimaryCTAButton
                      style={{
                        width: "auto",
                        padding: "10px 10px",
                      }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </PrimaryCTAButton>
                  </ButtonWrapper>
                </FormContainer>
              )}
            </Formik>
          </Container>
        </DrawerContainer>
      </Modal>
    </>
  );
};

export default BulkUpdate;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  & .status_row {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    align-items: center;
    & .status_name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
  }
  & .status-list {
    overflow: auto;
    height: 265px;
    // width: 60%;
    border: 4px solid transparent;
    border-radius: 8px;
    margin-left: 10px;
  }
  & .neutral_row {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
      & .add_circle {
        cursor: not-allowed !important;
      }
    }
    & .neutral_name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
    & .add_circle {
      margin: 0px 20px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 131, 140, 0.13);
      cursor: pointer;
      & .add_icon {
        font-size: 14px;
        color: ${COLORS.BTN_GREEN};
      }
    }
  }
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 65%;
  }
`;

const FormContainer = styled.div`
  // width: 100%;
  padding-top: 14px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  line-height: 1.71;
  backface-visibility: hidden;
  // max-height: 535px;
  overflow: auto;
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const StyledForm = styled.div``;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
`;
